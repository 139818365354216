import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandCategoryPayload } from '../../types/payloads';
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getBrandCategory } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import BrandCategoryForm from "./BrandCategoryForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetBrandCategories, useEditBrandCategory } from "../../redux/hooks/brandCategoryHooks";
import { updateForm, fetchOne } from "../../lib/handlers";

export default function EditBrandCategory() {
    const [formData, setFormData] = useState<BrandCategoryPayload>({
		name: "",
  	});
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateBrandCategory = useEditBrandCategory();
	const getBrandCategories = useGetBrandCategories();
	const info = useAppSelector((state) => state.brandCategories);
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getBrandCategory, setFormData);
		}
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<BrandCategoryPayload>(formData, "brandCategory")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateBrandCategory(sanitizeValue(params.id), formData, () => {
				getBrandCategories(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Brand Category</span>
			</div>
			<BrandCategoryForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
			/>
    	</main>
  	);
};
