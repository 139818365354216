import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<CompanyModel> = {
	list: null,
	page: 0,
	count: 0,
};

const companySlice = createSlice({
	name: "companies", initialState,
	reducers: {
		updateCompanies: (state, action: PayloadAction<SlicePayload<CompanyModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateCompanies } = companySlice.actions;
export default companySlice.reducer;
