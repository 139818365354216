import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<BrandModel> = {
	list: null,
	page: 0,
	count: 0,
};

const brandSlice = createSlice({
	name: "brands", initialState,
	reducers: {
		updateBrands: (state, action: PayloadAction<SlicePayload<BrandModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateBrands } = brandSlice.actions;
export default brandSlice.reducer;
