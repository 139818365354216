import { getChannels, updateChannel, deleteChannel, addChannel } from "../../lib/api";
import { updateChannels } from "../features/channelSlice";
import type { AppDispatch } from "../store";
import { ChannelModel } from "../../types/models";
import { ChannelPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetChannels() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ChannelModel>(params, getChannels);
		dispatch(updateChannels({ list: result.list, count: result.count, page }));
	}
}

export function useAddChannel() {
	return async function (body: ChannelPayload, cb: () => void) {
		if (await mutationApi(addChannel, body, "Channel Added", "Unable to Add Channel")){
			cb();
		}
	}
}

export function useEditChannel() {
	return async function (id: number, body: ChannelPayload, cb: () => void) {
		if (await mutationApi(updateChannel, { id, body }, "Channel Updated", "Unable to Update Channel")){
			cb();
		}
	}
}

export function useDeleteChannel() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteChannel, "Channel")) {
			cb();
		}
	}
}
