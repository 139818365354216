import { getOutbounds, addOutbound } from "../../lib/api";
import { updateOutbounds } from "../features/outboundSlice";
import type { AppDispatch } from "../store";
import { OutboundModel } from "../../types/models";
import { OutboundPayload } from "../../types/payloads";
import { getApi, mutationApi } from "./apiHooks";
import { QueryParams } from "../../types/common";

export function useGetOutbounds() {
	return async function (dispatch: AppDispatch, params: QueryParams) {
		const page: number = (params?.offset ?? 0 / params?.limit ?? 10) + 1;
		const result = await getApi<OutboundModel>(params, getOutbounds);
		dispatch(updateOutbounds({ list: result.list, count: result.count, page }));
	}
}

export function useAddOutbound() {
	return async function (body: OutboundPayload, cb: () => void) {
		if (await mutationApi(addOutbound, body, "Outbound Added", "Unable to Add Outbound")){
			cb();
		}
	}
}
