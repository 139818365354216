import { getSalesCategories, updateSalesCategory, deleteSalesCategory, addSalesCategory } from "../../lib/api";
import { updateSalesCategories } from "../features/salesCategorySlice";
import type { AppDispatch } from "../store";
import { SalesCategoryModel } from "../../types/models";
import { SalesCategoryPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetSalesCategories() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<SalesCategoryModel>(params, getSalesCategories);
		dispatch(updateSalesCategories({ list: result.list, count: result.count, page }));
	}
}

export function useAddSalesCategory() {
	return async function (body: SalesCategoryPayload, cb: () => void) {
		if (await mutationApi(addSalesCategory, body, "Sales Category Added", "Unable to Add Sales Category")){
			cb();
		}
	}
}

export function useEditSalesCategory() {
	return async function (id: number, body: SalesCategoryPayload, cb: () => void) {
		if (await mutationApi(updateSalesCategory, { id, body }, "Sales Category Updated", "Unable to Update Sales Category")){
			cb();
		}
	}
}

export function useDeleteSalesCategory() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteSalesCategory, "Sales Category")) {
			cb();
		}
	}
}
