import { getItemTypes, updateItemType, deleteItemType, addItemType } from "../../lib/api";
import { updateItemTypes } from "../features/itemTypeSlice";
import type { AppDispatch } from "../store";
import { ItemTypeModel } from "../../types/models";
import { ItemTypePayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetItemTypes() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ItemTypeModel>(params, getItemTypes);
		dispatch(updateItemTypes({ list: result.list, count: result.count, page }));
	}
}

export function useAddItemType() {
	return async function (body: ItemTypePayload, cb: () => void) {
		if (await mutationApi(addItemType, body, "Item Type Added", "Unable to Add Item Type")){
			cb();
		}
	}
}

export function useEditItemType() {
	return async function (id: number, body: ItemTypePayload, cb: () => void) {
		if (await mutationApi(updateItemType, { id, body }, "Item Type Updated", "Unable to Update Item Type")){
			cb();
		}
	}
}

export function useDeleteItemType() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteItemType, "Item Type")) {
			cb();
		}
	}
}
