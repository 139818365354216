import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandCategoryModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<BrandCategoryModel> = {
	list: null,
	page: 0,
	count: 0,
};

const brandCategorySlice = createSlice({
	name: "brandCategories", initialState,
	reducers: {
		updateBrandCategories: (state, action: PayloadAction<SlicePayload<BrandCategoryModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateBrandCategories } = brandCategorySlice.actions;
export default brandCategorySlice.reducer;
