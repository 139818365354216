import { getWarehouses, updateWarehouse, deleteWarehouse, addWarehouse } from "../../lib/api";
import { updateWarehouses } from "../features/warehouseSlice";
import type { AppDispatch } from "../store";
import { WarehouseModel } from "../../types/models";
import { WarehousePayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetWarehouses() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<WarehouseModel>(params, getWarehouses);
		dispatch(updateWarehouses({ list: result.list, count: result.count, page }));
	}
}

export function useAddWarehouse() {
	return async function (body: WarehousePayload, cb: () => void) {
		if (await mutationApi(addWarehouse, body, "Warehouse Added", "Unable to Add Warehouse")){
			cb();
		}
	}
}

export function useEditWarehouse() {
	return async function (id: number, body: WarehousePayload, cb: () => void) {
		if (await mutationApi(updateWarehouse, { id, body }, "Warehouse Updated", "Unable to Update Warehouse")){
			cb();
		}
	}
}

export function useDeleteWarehouse() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteWarehouse, "Warehouse")) {
			cb();
		}
	}
}
