export const queryKeys = {
    roles:"roles",
    users: "users",
    companies: "companies",
    brands: "brands",
    brandCategories: "brandCategories",
    salesCategories: "salesCategories",
    channels: "channels",
    customers: "customers",
    vendors: "vendors",
    countries: "countries",
    productCategories: "productCategories",
    items: "items",
    inbounds: "inbounds",
    outbounds: "outbounds",
    itemTypes: "itemTypes",
    classifications: "classifications",
    uoms: "uoms",
    sizes: "sizes",
    warehouses: "warehouses",
    currencies: "currencies",
    transfers: "transfers",
    demands: "demands",
    dashboards: "dashboards",
    passwords: "passwords",
    shipments: "shipments",
}