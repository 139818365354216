import { getCurrencies, updateCurrency, deleteCurrency, addCurrency } from "../../lib/api";
import { updateCurrencies } from "../features/currencySlice";
import type { AppDispatch } from "../store";
import { CurrencyModel } from "../../types/models";
import { CurrencyPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetCurrencies() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<CurrencyModel>(params, getCurrencies);
		dispatch(updateCurrencies({ list: result.list, count: result.count, page }));
	}
}

export function useAddCurrency() {
	return async function (body: CurrencyPayload, cb: () => void) {
		if (await mutationApi(addCurrency, body, "Currency Added", "Unable to Add Currency")){
			cb();
		}
	}
}

export function useEditCurrency() {
	return async function (id: number, body: CurrencyPayload, cb: () => void) {
		if (await mutationApi(updateCurrency, { id, body }, "Currency Updated", "Unable to Update Currency")){
			cb();
		}
	}
}

export function useDeleteCurrency() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteCurrency, "Currency")) {
			cb();
		}
	}
}
