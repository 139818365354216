import { TextField, Select, MenuItem, Autocomplete, Chip } from '@mui/material';
import { styles, getThemeSelectStyle, getThemeAutocompleteChipStyle } from "../../lib/styles";
import { CustomerPayload } from "../../types/payloads";
import { SalesCategoryModel, ChannelModel, UserModel } from "../../types/models";
import FormFooter from "../common/FormFooter";
import { useTheme } from "@mui/material/styles";
import { useTheme as themeContext } from "../../ThemeContext";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: CustomerPayload;
	salesCategories: SalesCategoryModel[];
	channels: ChannelModel[];
	users: UserModel[];
	setInchargeList: (e: any) => void;
	inchargeList: string;
};

export default function CustomerForm(props: Props) {
	const theme = useTheme();
	const { theme: themeCtx } = themeContext();

	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Sales Category<span className="text-red-400">*</span></h2>
					<Select
						name="salesCategory"
						value={`${props.formData?.salesCategory}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.salesCategories?.map((item: SalesCategoryModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Channel<span className="text-red-400">*</span></h2>
					<Select
						name="channel"
						value={`${props.formData?.channel}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.channels?.map((item: ChannelModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Customer Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Customer Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Person In Charge<span className="text-red-400">*</span></h2>
					<Autocomplete
                    	multiple
                    	id="person-in-charge-autocomplete"
                    	options={props.users}
                    	getOptionLabel={(option) => option.name}
						value={props.users?.filter(user => props.inchargeList?.includes(`${user?.id}`))}
                    	onChange={(event, value) => {
                        	props.setInchargeList(value.map((user: UserModel) => user.id).join(','));
                    	}}
                    	filterSelectedOptions
						renderTags={(value, getTagProps) =>
              				value.map((user, index) => (
                				<Chip
                  					label={user.name}
                  					{...getTagProps({ index })}
                  					className={`${
                    					themeCtx === 'dark' ? 'bg-[#2a2f3a]' : 'bg-gray-200'
									}`}
									sx={getThemeAutocompleteChipStyle(themeCtx)}
                				/>
              				))
            			}
                    	renderInput={(params) => (
                        	<TextField
                        		{...params}
                        		inputProps={{
                            		...params.inputProps,
                            		className: 'dark:text-white text-black',
                        		}}
							sx={styles.autocompleteInputStyle}
                        	className="bg-slate-100 dark:bg-[#202829] rounded-lg"
                        />
                    )}
                />	
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
