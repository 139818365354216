import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { TextField, Box, Link, IconButton } from '@mui/material';
import logo from '../assets/logo-white.png';
import logoMini from '../assets/minlogo-black.png';
import { LoginPayload } from '../lib/payload';
import { validate } from '../lib/validation';
import { setItem, showNotification } from '../lib/helpers';
import { login, setAuthHeader, forgotPassword } from '../lib/api';
import { LoginResult } from '@/lib/models';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
	cred: LoginResult | null;
	setCred: (value: LoginResult) => void;
};

export default function LoginPage({ cred, setCred }: Props) {
	const [showForm, setShowForm] = useState(false);
  	const [formData, setFormData] = useState<LoginPayload>({
    	email: '',
    	password: '',
  	});
  	const [showPassword, setShowPassword] = useState(false);
  	const [showForgotPassword, setShowForgotPassword] = useState(false);
  	const navigate = useNavigate();

  	useEffect(() => {
    	if (cred && cred?.token) {
      		navigate("/admin");
    	}
  	}, [cred, navigate]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      if (validate(formData, "login")) {
        const response = await login(formData);
        if (response?.success) {
          const credentials = response?.result as LoginResult;
          setItem('credentials', credentials);
			setCred(credentials);
			setAuthHeader(credentials?.token ?? null);
        } else {
          showNotification("error", "Invalid Credentials");
        }
      } else {
        showNotification("error", "Missing Required Field");
      }
    } catch (err) {
      console.error(err);
    }
  };

  	const handleForgotPasswordSubmit = async (e: any) => {
    	try {
      		e.preventDefault();
      		if (formData.email) {
        		const response = await forgotPassword(formData);
				if (response?.success) {
					setShowForgotPassword(false);
					showNotification("success", "A New Password has been sent to your email");
				} else {
					showNotification("error", "Unable to verify email");
				}
			} else {
				showNotification("error", "Email is required.");
			}
		} catch (err) {
			console.error(err);
		}
  	};

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-[#7c5082] overflow-hidden">
      {/* Background logo */}
      <motion.div
        initial={{ x: '0%', opacity: 0 }}
        animate={{ x: showForm ? '-20%' : '0%', opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="absolute flex items-center justify-center w-full h-full"
        onAnimationComplete={() => setShowForm(true)}
      >
        <img src={logo} alt="Logo" className="w-[30vw] h-auto" />
      </motion.div>

      {/* Form */}
      {showForm && !showForgotPassword && (
        <motion.div
          initial={{ x: '100%', opacity: 0, scale: 1 }}
          animate={{ x: '50%', opacity: 1, scale: 1 }}
          transition={{ type: 'tween', stiffness: 50, duration: 1.5 }}
          className="absolute flex flex-col items-center p-8 bg-white rounded-lg shadow-lg"
          style={{ width: '30vw' }}
        >
          <img src={logoMini} alt="Logo" width={100} height={100} className="mb-4" />
          <h2 className="mb-4 text-2xl font-Poppins text-[#7c5082] font-bold">Login</h2>
          <Box
            component="form"
            className="flex flex-col space-y-4 w-full"
          >
            <TextField
              name='email'
              variant="outlined"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
              InputProps={{
                style: { backgroundColor: 'white', height: '40px' },
              }}
            />
            <TextField
              name='password'
              variant="outlined"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              InputProps={{
                style: { backgroundColor: 'white', height: '40px' },
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <div className="flex justify-center items-center">
              <button onClick={handleSubmit} className="relative inline-flex items-center px-12 py-1 overflow-hidden text-lg font-medium text-[#7c5082] border-2 border-[#7c5082] rounded-md hover:text-white group hover:bg-[#7c5082]">
                <span className="absolute left-0 block w-full h-0 transition-all bg-[#7c5082] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span className="relative">Login</span>
              </button>
            </div>
            <Link href="#" onClick={() => setShowForgotPassword(true)}>
              <p className="text-[#7c5082] font-semibold font-Poppins hover:underline mt-2 text-sm">Forgot Password?</p>
            </Link>
          </Box>
        </motion.div>
      )}

      {/* Forgot Password Form */}
      {showForgotPassword && (
        <motion.div
          initial={{ x: '100%', opacity: 0, scale: 1 }}
          animate={{ x: '50%', opacity: 1, scale: 1 }}
          transition={{ type: 'tween', stiffness: 50, duration: 1.5 }}
          className="absolute flex flex-col items-center p-8 bg-white rounded-lg shadow-lg"
          style={{ width: '30vw' }}
        >
          <img src={logoMini} alt="Logo" width={100} height={100} className="mb-4" />
          <h2 className="mb-4 text-2xl font-Poppins text-[#7c5082] font-bold">Forgot Password</h2>
          <Box
            component="form"
            className="flex flex-col space-y-4 w-full"
            onSubmit={handleForgotPasswordSubmit}
          >
            <TextField
              name='email'
              variant="outlined"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
              InputProps={{
                style: { backgroundColor: 'white', height: '40px' },
              }}
            />
            <div className="flex justify-center items-center">
              <button type="submit" className="relative inline-flex items-center px-12 py-1 overflow-hidden text-lg font-medium text-[#7c5082] border-2 border-[#7c5082] rounded-md hover:text-white group hover:bg-[#7c5082]">
                <span className="absolute left-0 block w-full h-0 transition-all bg-[#7c5082] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span className="relative">Verify</span>
              </button>
            </div>
            <Link href="#" onClick={() => setShowForgotPassword(false)}>
              <p className="text-[#7c5082] font-semibold font-Poppins hover:underline mt-2 text-sm">Back to Login</p>
            </Link>
          </Box>
        </motion.div>
      )}
    </div>
  );
};
