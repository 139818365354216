import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getShipmentTypes, getBroker } from '../../lib/api';
import { ShipmentTypeModel, BrokerModel } from '../../types/models';
import { BrokerPayload, BrokerShipmentPayload  } from '../../types/payloads';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import BrokerForm from "./BrokerForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetBrokers, useEditBroker } from "../../redux/hooks/brokerHooks";
import { updateForm, fetchList, fetchOne } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function EditBroker() {
	const [formData, setFormData] = useState<BrokerPayload>({
		code: "", name: "", shipmentTypes: [],
	});
    const [shipmentTypes, setShipmentTypes] = useState<ShipmentTypeModel[]>([]);
    const [shipmentList, setShipmentList] = useState("");
    const navigate = useNavigate();
    const params = useParams();
	const dispatch = useAppDispatch();
	const updateBroker = useEditBroker();
	const getBrokers = useGetBrokers();
	const info = useAppSelector((state) => state.brokers);

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getBroker, initForm);
			fetchList(
				[getShipmentTypes],
				queryParams,
				[setShipmentTypes],
			);
		}
	}, [params]);

	const initForm = (data: BrokerModel) => {
		const formValue: BrokerPayload = {
			name: data?.name,
			code: data?.code,
		};
		const shipment: number[] = data?.shipmentTypes?.map(item => item?.shipmentType);
		setShipmentList(shipment.join(","));
		setFormData(formValue);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (shipmentList?.length > 0) {
			const shipment: BrokerShipmentPayload[] = [];
			const shipmentSplit = shipmentList.split(",");
			for (let item of shipmentSplit) {
				shipment.push({
					shipmentType: sanitizeValue(Number(item)),
				});
			}
			formData.shipmentTypes = shipment;
			if (validate(formData, "broker")) {
				const updateParams: Params = {
					offset: (info.page - 1) * 10,
					limit: 10,
				};
				updateBroker(sanitizeValue(params?.id), formData, () => {
					getBrokers(dispatch, updateParams);
					navigate(-1);
				});
			}
			else {
				showNotification("error", "Missing Required Field");
			}
		} else {
			showNotification("error", "Person In Charge Cannot be Empty");
		}
	};

    return (
        <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Broker</span>
			</div>
           	<BrokerForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				shipmentTypes={shipmentTypes}
				setShipmentList={setShipmentList}
				shipmentList={shipmentList}
			/> 
        </main>
    );
}
