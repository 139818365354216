import { showNotification } from "../../lib/helpers";
import { QueryParams } from "../../types/common";

export type Params = {
	offset: number;
	limit: number;
};
type GetFunc = (params: QueryParams) => Promise<any>;
type GetSingleFunc = (id: number) => Promise<any>;
type DeleteFunc = (id: number) => Promise<any>;
type AddFunc<T> = (body: T) => Promise<any>;

export async function getApi<T>(params: QueryParams, func: GetFunc) {
	try {
		const response = await func(params);
		let list: T[] = [];
		let count = 0;
		if (response?.success) {
			list = response?.result?.list ?? [];
			count = response?.result?.count ?? 0;
		}
		return { count, list };
	} catch (err) {
		console.error(err);
		return { count: 0, list: [] };
	}
}

export async function getSingleApi<T>(func: GetSingleFunc, id: number) {
	try {
		const response = await func(id);
		if (response.success) {
			return response.result as T;
		}
		return null;
	} catch (err) {
		console.error(err);
		return null;
	}
}

export async function mutationApi<T>(func: AddFunc<T>, body: T, successMsg: string, errorMsg: string): Promise<boolean> {
	try {
		const response = await func(body);
		if (response?.success) {
			showNotification("success", successMsg);
			return true;
		}
		showNotification("error", response?.message ?? "Something Went Wrong");
		return false;
	} catch (err) {
		console.error(err);
		showNotification("error", errorMsg);
		return false;
	}
}

export async function deleteApi(id: number, func: DeleteFunc, item: string) {
	try {
		await func(id);
		showNotification("success", `${item} Deleted`);
		return true;
	} catch (err) {
		console.error(err);
		showNotification("error", `Unable to delete ${item}`);
		return false;
	}
}

