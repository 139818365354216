import { useEffect, useState } from 'react';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { CustomerModel, LoginResult } from '../../types/models';
import { TableItem } from "../../types/common";
import { useGetCustomers, useDeleteCustomer } from "../../redux/hooks/customerHooks";
import { Params } from "../../redux/hooks/apiHooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import DeleteDialog from "../common/DeleteDialog";
import TableRowItem from "../common/TableRowItem";
import { handleDelete } from "../../lib/handlers";

const headers: string[] = [ "Sales Category", "Channel", "Customer Name", "Person In Charge", "Created On", ""];
const limit = 10;

export default function Customers({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
	const info = useAppSelector((state) => state.customers);
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
	const [tableData, setTableData] = useState<TableItem[][]>([]);
	const { theme } = useTheme();
	const [params] = useState<Params>({ offset: 0, limit });
	const getCustomers = useGetCustomers();
	const deleteCustomer = useDeleteCustomer();

	useEffect(() => {
		if (!info.list) {
			getData();
		} else if (info?.list?.length){
			prepareData(info.list);
		}
	}, [info]);

	const getData = () => getCustomers(dispatch, params);
	const prepareData = (items: CustomerModel[]) => {
		const data: TableItem[][] = [];
		let counter = 0;
		for (const item of items) {
			data.push([
				{ key: `${counter}-1`, value: item?.salesCategoryName ?? "", valueType: "text" },
				{ key: `${counter}-2`, value: item?.channelName ?? "", valueType: "text" },
				{ key: `${counter}-3`, value: item?.name ?? "", valueType: "text" },
				{ key: `${counter}-4`, value: item?.personList ?? "", valueType: "text" },
				{ key: `${counter}-5`, value: item?.createdOn ?? "", valueType: "date" },	
				{ key: `${counter}-6`, id: item.id, value: "", valueType: "action", actions: [
					{ actionType: "edit", handler: (id: number) => navigate(`${id}/edit`) },
					{ actionType: "delete", handler: handleDeleteClick },
				] },
			]);
			counter += 1;
		}
		setTableData(data);
	};

	const handlePageChange = (page: number) => {
		const offset = (page - 1) * limit
		getCustomers(dispatch, { offset, limit });
		setCurrentPage(page);
	};

	const handleAddClick = () => navigate("add");
    const handleDeleteClick = (id: number) => handleDelete(id, true, setDeleteId, setIsDeleteDialogOpen);
    const handleDeleteConfirm = () => {
        if (deleteId) deleteCustomer(deleteId, getData);
        setIsDeleteDialogOpen(false);
    };

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={headers}
                totalRows={info.count}
                colSpan={headers.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={handlePageChange}
				reload={getData}
				cred={cred}
            >
               {tableData.map((items: TableItem[]) => (
					<TableRowItem key={items[0].key} items={items} />
				))} 
            </CommonTable>
			<DeleteDialog
				isOpen={isDeleteDialogOpen}
				onClose={() => handleDelete(null, false, setDeleteId, setIsDeleteDialogOpen)}
				onConfirm={handleDeleteConfirm}
				title="Confirm Delete"
				text="Are you sure you want to delete this Customer?"
				theme={theme}
			/>
        </div>
    );
};
