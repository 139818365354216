import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemPayload } from '../../lib/payload';
import {
	BrandModel, BrandCategoryModel, ClassificationModel,
	CurrencyModel, ItemModel, ItemTypeModel,
	ProductCategoryModel, SizeModel, UomModel,
} from '../../types/models';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import {
	getBrandCategories, getBrands, getClassifications, getCurrencies,
	getItemTypes, getProductCategories, getSizes, getUoms, getItems,
} from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import ItemForm from "./ItemForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetItems, useAddItem } from "../../redux/hooks/itemHooks";
import { updateForm, fetchList } from "../../lib/handlers";
import { styles } from "../../lib/styles";

const queryParams = { offset: 0, limit: 1000 };
export default function AddItem() {
	const [formData, setFormData] = useState<ItemPayload>({
		itemType: 0, brandCategory: 0, productCategory: 0,
		brand: 0, parentCode: "", classification: 0,
		size:0, itemCode: "", itemName: "",
		items: [], itemCategory: 'INDIVIDUAL',
		uom: 0, purchasePrice: 0,
		currency: 0, suggestedRetailPrice: 0,
	});
	const [ itemTypes, setItemTypes ] = useState<ItemTypeModel[]>([]);
	const [ items, setItems ] = useState<ItemModel[]>([]);
	const [ brandCategories, setBrandCategories ] = useState<BrandCategoryModel[]>([]);
	const [ productCategories, setProductCategories ] = useState<ProductCategoryModel[]>([]);
	const [ brands, setBrands ] = useState<BrandModel[]>([]);
	const [ uoms, setUoms ] = useState<UomModel[]>([]);
	const [ classifications, setClassifications ] = useState<ClassificationModel[]>([]);
	const [ sizes, setSizes ] = useState<SizeModel[]>([]);
	const [ currencies, setCurrencies ] = useState<CurrencyModel[]>([]);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addItem = useAddItem();
	const refetchItems = useGetItems();
	const info = useAppSelector((state) => state.items);
  
	useEffect(() => {
		fetchList(
			[
				getItemTypes, getBrandCategories, getProductCategories, getBrands,
				getUoms, getClassifications, getSizes, getCurrencies, getItems,
			],
			queryParams,
			[
				setItemTypes, setBrandCategories, setProductCategories, setBrands,
				setUoms, setClassifications, setSizes, setCurrencies, setItems,
			]
		);
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<ItemPayload>(formData, "item")) {
			formData.itemType = sanitizeValue(formData.itemType);
			formData.brandCategory = sanitizeValue(formData.brandCategory);
			formData.brand = sanitizeValue(formData.brand);
			formData.uom = sanitizeValue(formData.uom);
			formData.classification = sanitizeValue(formData.classification);
			formData.size = sanitizeValue(formData.size);
			formData.currency = sanitizeValue(formData.currency);
			formData.purchasePrice = sanitizeValue(formData.purchasePrice);
			formData.suggestedRetailPrice = sanitizeValue(formData.suggestedRetailPrice);
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addItem(formData, () => {
				refetchItems(dispatch, updateParams);
				navigate(-1);
			});	
		} else {
			showNotification("error", "Missing Required Field");
		}
	};

    const handleAddItem = () => {
        setFormData(prevData => ({
            ...prevData,
            items: [...(prevData.items ?? []), { itemCode: '', quantity: 0 }],
        }));
    };

    const handleItemChange = (index: number, e: any) => {
        const { name, value } = e.target;
        const newItems = [...(formData.items ?? [])];
        if (name === "itemData") {
            const itemDataSplit = value?.split("_");
            const itemId = Number(sanitizeValue(itemDataSplit[0]));
            const itemCode = itemDataSplit[1];
            newItems[index] = {
                ...newItems[index],
                itemId,
                itemCode,
                packItemCode: formData.itemCode,

            };
        } else {
            newItems[index] = {
                ...newItems[index],
                quantity: sanitizeValue(value),
            }
        }
        setFormData({
            ...formData,
            items: newItems,
        });
    };

    const handleRemoveItem = (index: number) => {
        if (formData.items) {
            const newItems = formData.items.filter((_, i) => i !== index);
            setFormData({
                ...formData,
                items: newItems,
            });
		}
    };
    
  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>
					Add Item
				</span>
			</div>
			<ItemForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				itemTypes={itemTypes}
				brandCategories={brandCategories}
				productCategories={productCategories}
				brands={brands}
				uoms={uoms}
				classifications={classifications}
				sizes={sizes}
				currencies={currencies}
				items={items}
				handleItemChange={handleItemChange}
				handleAddItem={handleAddItem}
				handleRemoveItem={handleRemoveItem}
				isEdit={false}
			/>
    	</main>
  	);
};
