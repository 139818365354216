import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPayload } from '../../types/payloads';
import { RoleModel, MetadataModel } from "../../types/models";
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getRoles, getMetadata, getRole } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import UserForm from "./UserForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetUsers, useAddUser } from "../../redux/hooks/userHooks";
import { updateForm, fetchList, fetchOne } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function AddUser() {
    const [formData, setFormData] = useState<UserPayload>({
		name: "", email: "", userId: "", permissions: [],
  	});
	const [roles, setRoles] = useState<RoleModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addUser = useAddUser();
	const getUsers = useGetUsers();
	const info = useAppSelector((state) => state.users);

	useEffect(() => {
		fetchList([getRoles], queryParams, [setRoles]);
	}, []);

	useEffect(() => {
		if (formData?.role) {
			fetchOne(sanitizeValue(formData.role), getRole, (data: RoleModel) => {
				const form: UserPayload = {...formData};
				const modules = new Set<number>();
				for (const item of data?.permissions) {
					modules.add(item.module);
				}
				const list = [...data.permissions];
				fetchOne(0, getMetadata, (result: MetadataModel) => {
					for (const item of result?.modules) {
						if (!modules.has(item.id)) {
							list.push({
								module: item.id,
								moduleName: item.name,
								hasAdd: 0, hasEdit: 0,
								hasView: 0, hasDelete: 0,
							});
						}
					}
					form.permissions = list;
					setFormData(form);
				});
			});
			
		}
	}, [formData.role]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<UserPayload>(formData, "user")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addUser(formData, () => {
				getUsers(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add User</span>
			</div>
			<UserForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				setFormData={setFormData}
				roles={roles}
			/>
    	</main>
  	);
};
