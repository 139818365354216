import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UomPayload } from '../../types/payloads';
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import UomForm from "./UomForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetUoms, useAddUom } from "../../redux/hooks/uomHooks";
import { updateForm } from "../../lib/handlers";

export default function AddUom() {
    const [formData, setFormData] = useState<UomPayload>({
		uomGroup: "",
  	});
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addUom = useAddUom();
	const getUoms = useGetUoms();
	const info = useAppSelector((state) => state.uoms);


	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<UomPayload>(formData, "uom")) {
			formData.multiplier = sanitizeValue(formData.multiplier);
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addUom(formData, () => {
				getUoms(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Uom</span>
			</div>
			<UomForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
			/>
    	</main>
  	);
};
