import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { CustomerPayload, PersonInChargePayload } from '../../lib/payload';
import { validate } from '../../lib/validation';
import { ChannelModel, SalesCategoryModel, UserModel } from '../../types/models';
import { getChannels, getSalesCategories, getUsers } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import CustomerForm from "./CustomerForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetCustomers, useAddCustomer } from "../../redux/hooks/customerHooks";
import { updateForm, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function AddCustomer() {
    const [formData, setFormData] = useState<CustomerPayload>({
        name: '',
        salesCategory: 0,
        channel: 0,
        personInCharge: [],
    });
    const [ salesCategories, setSalesCategories ] = useState<SalesCategoryModel[]>([]);
    const [ channels, setChannels ] = useState<ChannelModel[]>([]);
    const [ users, setUsers ] = useState<UserModel[]>([]);
    const [inchargeList, setInchargeList] = useState("");
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addCustomer = useAddCustomer();
	const getCustomers = useGetCustomers();
	const info = useAppSelector((state) => state.customers);

    useEffect(() => {
		fetchList(
			[getSalesCategories, getChannels, getUsers],
			queryParams,
			[setSalesCategories, setChannels, setUsers],
		)
    }, []);

    const handleSubmit = (e: any) => {
		e.preventDefault();
		if (inchargeList?.length > 0) {
			const personInCharge: PersonInChargePayload[] = [];
			const inchargeSplit = inchargeList.split(",");
			for (let item of inchargeSplit) {
				personInCharge.push({
					user: sanitizeValue(Number(item)),
				});
			}
			formData.personInCharge = personInCharge;
			if (validate(formData, "customer")) {
				formData.salesCategory = sanitizeValue(formData.salesCategory);
				formData.channel = sanitizeValue(formData.channel);
				const updateParams: Params = {
					offset: (info.page - 1) * 10,
					limit: 10,
				};
				addCustomer(formData, () => {
					getCustomers(dispatch, updateParams);
					navigate(-1);
				});
			}
			else {
				showNotification("error", "Missing Required Field");
			}
		} else {
			showNotification("error", "Person In Charge Cannot be Empty");
		}
	};

	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Customer</span>
			</div>
    		<CustomerForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				users={users}
				salesCategories={salesCategories}
				channels={channels}
				setInchargeList={setInchargeList}
				inchargeList={inchargeList}
			/>
    	</main>
    );
};
