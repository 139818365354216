import { TextField, MenuItem, Select } from '@mui/material';
import { styles, getThemeSelectStyle } from "../../lib/styles";
import { ItemPayload } from "../../types/payloads";
import {
	ItemTypeModel, BrandCategoryModel, ProductCategoryModel,
	BrandModel, UomModel, ClassificationModel, SizeModel,
	CurrencyModel, ItemModel,
} from "../../types/models";
import FormFooter from "../common/FormFooter";
import { useTheme } from "@mui/material/styles";
import { MdDeleteForever } from 'react-icons/md';
import { sanitizeValue } from "../../lib/helpers";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: ItemPayload;
	itemTypes: ItemTypeModel[];
	brandCategories: BrandCategoryModel[];
	productCategories: ProductCategoryModel[];
	brands: BrandModel[];
	uoms: UomModel[];
	classifications: ClassificationModel[];
	sizes: SizeModel[];
	currencies: CurrencyModel[];
	items: ItemModel[];
	handleItemChange: (index: number, e: any) => void;
	handleAddItem: () => void;
	handleRemoveItem?: (index: number) => void;
	handleRemoveItemAsync?: (index: number, itemId: number) => Promise<void>;
	isEdit: boolean;
};

const itemTypeOptions = [
    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
    { value: "BUNDLE", label: "BUNDLE" },
];

export default function ItemForm(props: Props) {
	const theme = useTheme();
	const handleDeleteItem = (index: number, itemId: number) => {
		if (props.isEdit && props.handleRemoveItemAsync) {
			props.handleRemoveItemAsync(index, itemId);
		} else if (props.isEdit && props.handleRemoveItem) {
			props.handleRemoveItem(index);
		}
	}
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Item Type<span className="text-red-400">*</span></h2>
					<Select
						name="itemType"
						value={`${props.formData?.itemType}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.itemTypes?.map((item: ItemTypeModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Brand Category<span className="text-red-400">*</span></h2>
					<Select
						name="brandCategory"
						value={`${props.formData?.brandCategory}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.brandCategories?.map((item: BrandCategoryModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Product Category<span className="text-red-400">*</span></h2>
					<Select
						name="productCategory"
						value={`${props.formData?.productCategory}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.productCategories?.map((item: ProductCategoryModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Brand Code<span className="text-red-400">*</span></h2>
					<Select
						name="brand"
						value={`${props.formData?.brand}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.brands?.map((item: BrandModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.code}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Parent Code<span className="text-red-400">*</span></h2>
					<TextField
						name="parentCode"
						placeholder="Parent Code"
						variant="outlined"
						value={props.formData?.parentCode}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Item Code<span className="text-red-400">*</span></h2>
					<TextField
						name="itemCode"
						placeholder="Item Code"
						variant="outlined"
						value={props.formData?.itemCode}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Item Name<span className="text-red-400">*</span></h2>
					<TextField
						name="itemName"
						placeholder="Item Name"
						variant="outlined"
						value={props.formData?.itemName}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>UOM<span className="text-red-400">*</span></h2>
					<Select
						name="uom"
						value={`${props.formData?.uom}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.uoms?.map((item: UomModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.uomGroup}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Size<span className="text-red-400">*</span></h2>
					<Select
						name="size"
						value={`${props.formData?.size}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.sizes?.map((item: SizeModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.size}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Target Days Cover<span className="text-red-400">*</span></h2>
					<Select
						name="classification"
						value={`${props.formData?.classification}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.classifications?.map((item: ClassificationModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.classification}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Purchase Price<span className="text-red-400">*</span></h2>
					<TextField
						name="purchasePrice"
						placeholder="Purchase Price"
						variant="outlined"
						value={props.formData?.purchasePrice}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Currency<span className="text-red-400">*</span></h2>
					<Select
						name="currency"
						value={`${props.formData?.currency}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.currencies?.map((item: CurrencyModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.currency}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Suggested Retail Price<span className="text-red-400">*</span></h2>
					<TextField
						name="suggestedRetailPrice"
						placeholder="Suggested Retail Price"
						variant="outlined"
						value={props.formData?.suggestedRetailPrice}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Item Category<span className="text-red-400">*</span></h2>
					<Select
						name="itemCategory"
						value={`${props.formData?.itemCategory}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{itemTypeOptions?.map((item) => (
							<MenuItem key={item.value} value={item.value} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.label}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
			{props?.formData?.itemCategory?.toUpperCase() === 'BUNDLE' && (
				<div className='flex flex-col'>
                    {props?.formData.items?.map((item, index) => (
                        <div className='flex justify-center items-center' key={index}>
							<div>
								<h2 className={styles.labelStyle}>Item Code<span className="text-red-400">*</span></h2>
								<Select
									name="itemData"
									value={`${item.itemId}_${item.itemCode}`}
									onChange={(e) => props.handleItemChange(index, e)}
									displayEmpty
									inputProps={{className: 'dark:text-white text-black',}}
									className='bg-slate-100 dark:bg-[#202829]'
									sx={getThemeSelectStyle(theme)}
								>
									{props?.items?.map((item: ItemModel) => (
										<MenuItem key={item.id} value={`${item.id}_${item.itemCode}`} sx={{ fontFamily: 'Poppins, sans-serif' }}>
											{item.itemCode}
										</MenuItem>
                                    ))}
								</Select>
							</div>
							<div>
								<h2 className={styles.labelStyle}>Item Name<span className="text-red-400">*</span></h2>
								<Select
									name="itemData"
									value={`${item.itemId}_${item.itemCode}`}
									onChange={(e) => props.handleItemChange(index, e)}
									displayEmpty
									inputProps={{className: 'dark:text-white text-black',}}
									className='bg-slate-100 dark:bg-[#202829]'
									sx={getThemeSelectStyle(theme)}
								>
									{props?.items?.map((item: ItemModel) => (
										<MenuItem key={item.id} value={`${item.id}_${item.itemCode}`} sx={{ fontFamily: 'Poppins, sans-serif' }}>
											{item.itemName}
										</MenuItem>
									))}
								</Select>
							</div>
							<div>
								<h2 className={styles.labelStyle}>Quantity<span className="text-red-400">*</span></h2>
								<TextField
									name="quantity"
									placeholder="Quantity"
									variant="outlined"
									value={item?.quantity ?? ""}
									onChange={(e: any) => props.handleItemChange(index, e)}
									inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
									sx={styles.inputStyle}
									className='bg-slate-100 dark:bg-[#202829] rounded-lg'
								/>
							</div>
							<MdDeleteForever onClick={() => handleDeleteItem(index, sanitizeValue(item.itemId))} className="text-red-500 text-2xl cursor-pointer" />
                        </div>
                    ))}
                    <div className='flex justify-center items-center mt-6'>
                        <button type="button" onClick={props.handleAddItem} className='add-more-btn'>
                            Add More Items
                        </button>
                    </div>
                </div>	
			)}
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
