import { sanitizeValue } from "./helpers";
import { ApiSingleResponse, ApiResponse } from "../types/models";

type Params = { offset: number, limit: number };
type SetIdFunc = (id: number | null) => void;
type SetOpenFn = (flag: boolean) => void;
type SetFormFunc = (form: any) => void;
type GetOneFunc = (id: number) => Promise<ApiSingleResponse<any>>;
type GetListFunc = (params: Params) => Promise<ApiResponse<any>>;

export function handleDelete(id: number | null, flag: boolean, setId: SetIdFunc, setDialogOpen: SetOpenFn): void {
	setId(id);
	setDialogOpen(flag)
}

export function updateForm<T>(e: any, formData: T, setFormData: SetFormFunc) {
	const { name, value } = e.target;
	setFormData({ ...formData, [name]: value });
}

export function bodyBuilder<T>(form: T, numberFields: string[], optionalFields: string[]): T {
	const body: any = {
		...form,
	};
	for (const optField of optionalFields) {
		const data: number = sanitizeValue(form[optField as keyof typeof form]);
		if (data === 0) {
			body[optField] = undefined;
		}
	}
	for (const numField of numberFields) {
		body[numField] = sanitizeValue(form[numField as keyof typeof form]);
	}
	return body;
}

export async function fetchOne(id: number, func: GetOneFunc, cb: SetFormFunc) {
	try {
		const response = await func(id);
		if (response?.success && response?.result) {
			cb(response?.result);
		}
	} catch (err) {
		console.error(err);
	}
}

export async function fetchList(funcArr: GetListFunc[], params: Params, cbArr: SetFormFunc[]) {
	try {
		const responseArr = await Promise.all(funcArr.map(fn => fn(params)));
		for (let index = 0; index < responseArr.length; index++) {
			if (responseArr[index]?.success) {
				cbArr[index](responseArr[index]?.result?.list ?? []);
			}
		}
	} catch (err) {
		console.error(err);
	}
}
