import { deleteShipment, updateShipment, addShipment, getShipments } from "../../lib/api";
import type { AppDispatch } from "../store";
import { ShipmentModel } from "../../types/models";
import { ShipmentPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";
import { updateShipments } from "../features/shipmentSlice";

export function useGetShipments() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ShipmentModel>(params, getShipments);
		dispatch(updateShipments({ list: result.list, count: result.count, page }));
	}
}

export function useAddShipment() {
	return async function (body: ShipmentPayload, cb: () => void) {
		if (await mutationApi(addShipment, body, "Shipment Added", "Unable to Add Shipment")){
			cb();
		}
	}
}

export function useEditShipment() {
	return async function (id: number, body: ShipmentPayload, cb: () => void) {
		if (await mutationApi(updateShipment, { id, body }, "Shipment Updated", "Unable to Update Shipment")){
			cb();
		}
	}
}

export function useDeleteShipment() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteShipment, "Shipment")) {
			cb();
		}
	}
}
