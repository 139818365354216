import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetRoles, useAddRole } from "../../redux/hooks/roleHooks";
import { fetchOne, updateForm } from "../../lib/handlers";
import { RolePayload } from "../../types/payloads";
import { getMetadata } from '../../lib/api';
import { MetadataModel } from '../../types/models';
import RoleForm from './RoleForm';

export default function AddRole() {
	const [formData, setFormData] = useState<RolePayload>({ name: "", permissions: [] });
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addRole = useAddRole();
	const getRoles = useGetRoles();
	const info = useAppSelector((state) => state.roles);

	useEffect(() => {
		fetchOne(0, getMetadata, (result: MetadataModel) => {
			const form = {...formData};
			const permissionList = [...form.permissions];
			for (const module of result?.modules) {
				permissionList.push({
					module: module.id,
					moduleName: module.name,
					hasAdd: 0, hasEdit: 0,
					hasView: 0, hasDelete: 0,
				});
			}
			form.permissions = permissionList;
			setFormData(form);
		});
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<RolePayload>(formData, "role") && formData?.permissions?.length > 0) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addRole(formData, () => {
				getRoles(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Role</span>
			</div>
			<RoleForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				setFormData={setFormData}
			/>
    	</main>
  	);
}
