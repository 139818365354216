import { useEffect, useState } from 'react';
import { CgMenuRightAlt, CgMenu } from "react-icons/cg";
import {
	Menu, MenuItem, IconButton, Tooltip, Collapse, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, ListItemIcon,
	FormControlLabel, Typography,
} from "@mui/material";
import {
	MdAccountCircle, MdDashboard, MdExpandLess, MdExpandMore,
	MdOutlineManageSearch, MdSettings, MdExitToApp, MdOutlineInventory,
} from "react-icons/md";
import { PiPackageFill } from "react-icons/pi";
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';
import DarkLogo from "../assets/logo-black.png";
import LightLogo from "../assets/logo-white.png";
import DarkLogoMin from "../assets/minlogo-black.png";
import LightLogoMin from "../assets/logo.png";
import { ApiSingleResponse, LoginResult, User } from '../lib/models';
import { deleteAll } from '../lib/helpers';
import { getProfile, logout, setAuthHeader } from '../lib/api';

import Dashboard from '../components/dashboard/Dashboard';
import MaterialUISwitch from '../components/switch/MaterialUISwitch';
import AvgDemand from '../components/avgDemand/AvgDemand';
import Companies from '../components/company/Companies';
import EditCompany from '../components/company/EditCompany';
import AddBrand from '../components/brand/AddBrand';
import EditBrand from '../components/brand/EditBrand';
import AddItem from '../components/item/AddItem';
import EditItem from '../components/item/EditItem';
import AddClassification from '../components/classification/AddClassification';
import EditClassification from '../components/classification/EditClassification';
import EditWarehouse from '../components/warehouse/EditWarehouse';
import AddWarehouse from '../components/warehouse/AddWarehouse';
import Users from '../components/staff/Users';
import AddUser from '../components/staff/AddUser';
import ItemTypes from '../components/itemType/ItemTypes';
import AddItemTypes from '../components/itemType/AddItemType';
import EditItemTypes from '../components/itemType/EditItemType';
import BrandCategories from '../components/brandCategory/BrandCategories';
import AddBrandCategories from '../components/brandCategory/AddBrandCategory';
import EditBrandCategories from '../components/brandCategory/EditBrandCategory';
import AddCompany from '../components/company/AddCompany';
import ProductCategories from '../components/productCategory/ProductCategories';
import AddProductCategories from '../components/productCategory/AddProductCategory';
import EditProductCategories from '../components/productCategory/EditProductCategory';
import Brands from '../components/brand/Brands';
import Uoms from '../components/unit/Uoms';
import AddUom from '../components/unit/AddUom';
import EditUom from '../components/unit/EditUom';
import Roles from '../components/role/Roles';
import Items from '../components/item/Items';
import Classifications from '../components/classification/Classifications';
import Warehouses from '../components/warehouse/Warehouses';
import AddRole from '../components/role/AddRole';
import EditRole from '../components/role/EditRole';
import EditUser from '../components/staff/EditUser';
import SalesCategories from '../components/salesCategory/SalesCategories';
import AddSalesCategory from '../components/salesCategory/AddSalesCategory';
import EditSalesCategory from '../components/salesCategory/EditSalesCategory';
import Channels from '../components/channel/Channels';
import AddChannel from '../components/channel/AddChannel';
import EditChannel from '../components/channel/EditChannel';
import Customers from '../components/customer/Customers';
import AddCustomer from '../components/customer/AddCustomer';
import EditCustomer from '../components/customer/EditCustomer';
import Vendors from '../components/vendor/Vendors';
import AddVendor from '../components/vendor/AddVendor';
import Countries from '../components/country/Countries';
import AddCountry from '../components/country/AddCountry';
import EditCountry from '../components/country/EditCountry';
import EditVendor from '../components/vendor/EditVendor';
import Inbounds from '../components/inbound/Inbound';
import AddInbound from '../components/inbound/AddInbound';
import OutBounds from '../components/outbound/Outbound';
import Transfers from '../components/transfer/Transfer';
import AddOutbound from '../components/outbound/AddOutbound';
import Sizes from '../components/size/Sizes';
import AddSize from '../components/size/AddSize';
import EditSize from '../components/size/EditSize';
import Currencies from '../components/currency/Currencies';
import AddCurrency from '../components/currency/AddCurrency';
import EditCurrency from '../components/currency/EditCurrency';
import AddTransfer from '../components/transfer/AddTransfer';
import AddAvgDemand from '../components/avgDemand/AddAvgDemand';
import ViewItem from '../components/item/ViewItem';
import Profile from './Profile';
import ShipmentTypes from "../components/shipmentType/ShipmentTypes";
import AddShipmentType from "../components/shipmentType/AddShipmentType";
import EditShipmentType from "../components/shipmentType/EditShipmentType";
import Brokers from "../components/broker/Brokers";
import AddBroker from "../components/broker/AddBroker";
import EditBroker from "../components/broker/EditBroker";
import Shipments from '../components/shipment/Shipments';
import AddShipment from '../components/shipment/AddShipment';
import EditShipment from '../components/shipment/EditShipment';

interface SubmenuItem {
	id: number;
	link: string;
	label: string;
	subLabel: string;
	viewEnabled: boolean;
}

interface LinkItem {
	id: number;
	link?: string;
	icon: JSX.Element;
	label: string;
	viewEnabled: boolean;
	submenu?: SubmenuItem[];
}

type Props = {
	cred: LoginResult | null;
	setCred: (value: LoginResult | null) => void;
};

export default function AdminPage({ cred, setCred }: Props){
const { theme, setTheme } = useTheme();
const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
const [anchorProfileMenu, setAnchorProfileMenu] = useState<null | HTMLElement>(null);
const [expandedSubmenu, setExpandedSubmenu] = useState<number | null>(null);
const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
const [user, setUser] = useState<User | null>(null);
const [isLoading, setIsLoading] = useState(true);
const location = useLocation();
const navigate = useNavigate();

useEffect(() => {
	if(!cred || !cred?.token) {
		navigate("/");
	} else {
		setIsLoading(false);
	}
}, [cred, navigate]);

useEffect(() => {
	const handleScroll = () => {
		if (window.scrollY > 0) {
			setIsNavbarTransparent(false);
		} else {
			setIsNavbarTransparent(true);
		}
	};

	window.addEventListener('scroll', handleScroll);
	return () => {
		window.removeEventListener('scroll', handleScroll);
	};
}, []);


useEffect(() => {
	const fetchProfile = async () => {
		try {
			const response: ApiSingleResponse = await getProfile();
			if (response.success) {
				setUser(response.result as User);
			} 
		} catch (err) {
			console.error(err);
		}
	};
	fetchProfile();
}, []);


useEffect(() => {
	const storedTheme = localStorage.getItem('theme');
	if (storedTheme) {
	  setTheme(storedTheme === 'dark' ? 'dark' : 'light');
	}
  }, [setTheme]);

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	const newTheme = event.target.checked ? 'dark' : 'light';
	setTheme(newTheme);
	localStorage.setItem('theme', newTheme);
  };

const links: LinkItem[] = [
	{
		id: 1,
		link: "/admin",
		icon: <MdDashboard />,
		label: "Dashboard",
		viewEnabled: true
	},
	{
		id: 2,
		link: "/admin/*",
		icon: <MdOutlineManageSearch />,
		label: "Administration",
		viewEnabled: true,
		submenu: [
			{
				id: 2.1,
				link: "/admin/roles",
				label: "✾ Roles",
				subLabel:"R",
				viewEnabled: true
			},
			{
				id: 2.2,
				link: "/admin/users",
				label: "✾ Users",
				subLabel:"U",
				viewEnabled: true
			},
			{
				id: 2.3,
				link: "/admin/companies",
				label: "✾ Companies",
				subLabel:"C",
				viewEnabled: true
			},
			{
				id: 2.4,
				link: "/admin/warehouses",
				label: "✾ Warehouses",
				subLabel:"W",
				viewEnabled: true
			},
			{
				id: 2.5,
				link: "/admin/sales-categories",
				label: "✾ Sales Categories",
				subLabel:"SC",
				viewEnabled: true
			},
			{
				id: 2.6,
				link: "/admin/channels",
				label: "✾ Channels",
				subLabel:"C",
				viewEnabled: true
			},
			{
				id: 2.7,
				link: "/admin/customers",
				label: "✾ Customers",
				subLabel:"C",
				viewEnabled: true
			},
			{
				id: 2.8,
				link: "/admin/countries",
				label: "✾ Countries",
				subLabel:"C",
				viewEnabled: true
			},
			{
				id: 2.9,
				link: "/admin/vendors",
				label: "✾ Vendors",
				subLabel:"V",
				viewEnabled: true
			},
			{
				id: 2.10,
				link: "/admin/currencies",
				label: "✾ Currencies",
				subLabel:"C",
				viewEnabled: true
			},
		],
	},
	{
		id: 3,
		link: "/admin/*",
		icon: <MdOutlineInventory />,
		label: "Inventory",
		viewEnabled: true,
		submenu: [
			{ 
				id: 3.1,
				link: "/admin/itemTypes",
				label: "✾ Item Types",
				subLabel:"IT",
				viewEnabled: true
			},
			{ 
				id: 3.2,
				link: "/admin/categoryBrands",
				label: "✾ Brand Categories",
				subLabel:"BC",
				viewEnabled: true
			},
			{
				id: 3.3,
				link: "/admin/productCategories",
				label: "✾ Product Categories",
				subLabel:"PC",
				viewEnabled: true
			},
			{
				id: 3.4,
				link: "/admin/brands",
				label: "✾ Brands",
				subLabel:"B",
				viewEnabled: true
			},
			{
				id: 3.5,
				link: "/admin/units",
				label: "✾ Units",
				subLabel:"U",
				viewEnabled: true
			},
			{
				id: 3.6,
				link: "/admin/classifications",
				label: "✾ Classifications",
				subLabel:"CL",
				viewEnabled: true
			},
			{
				id: 3.7,
				link: "/admin/sizes",
				label: "✾ Size",
				subLabel:"S",
				viewEnabled: true
			},
			{
				id: 3.8,
				link: "/admin/items",
				label: "✾ Item Master Data",
				subLabel:"I",
				viewEnabled: true
			},
			{
				id: 3.9,
				link: "/admin/shipment-types",
				label: "✾ Shipment Types",
				subLabel: "ST",
				viewEnabled: true,
			},
			{
				id: 3.10,
				link: "/admin/brokers",
				label: "✾ Brokers",
				subLabel: "B",
				viewEnabled: true,
			},
			{
				id: 3.11,
				link: "/admin/shipments",
				label: "✾ Shipments",
				subLabel: "S",
				viewEnabled: true,
			},
			// {
			//     id: 3.10,
			//     link: "/admin/inventoryValue",
			//     label: "✾ Inventory Values",
			//     subLabel:"I V",
			//     viewEnabled: true
			// },
		],
	},
	{
		id: 4,
		link: "/admin/*",
		icon: <PiPackageFill />,
		label: "Inventory Transactions",
		viewEnabled: true,
		submenu: [
			{
				id: 4.1,
				link: "/admin/inbounds",
				label: "✾ Inbounds",
				subLabel:"I",
				viewEnabled: true
			},
			{
				id: 4.2,
				link: "/admin/outbounds",
				label: "✾ Outbounds",
				subLabel:"OB",
				viewEnabled: true
			},
			{
				id: 4.3,
				link: "/admin/transfers",
				label: "✾ Transfers",
				subLabel:"T",
				viewEnabled: true
			},
			// {
			//     id: 4.4,
			//     link: "/admin/stockReturn",
			//     label: "✾ Stocks Return",
			//     subLabel:"SR",
			//     viewEnabled: true
			// },
			// {
			//     id: 4.5,
			//     link: "/admin/stockAllocation",
			//     label: "✾ Stocks Allocation",
			//     subLabel:"SA",
			//     viewEnabled: true
			// },
		],
	},
	{
		id: 5,
		link: "/admin/*",
		icon: <MdOutlineManageSearch />,
		label: "Item Reports",
		viewEnabled: true,
		submenu: [
			{
				id: 5.1,
				link: "/admin/averageDemand",
				label: "✾ Average Demand",
				subLabel:"S",
				viewEnabled: true
			},
			// {
			//     id: 5.2,
			//     link: "/admin/staff",
			//     label: "✾ Aging Report",
			//     subLabel:"S",
			//     viewEnabled: true
			// },
		],
	},
	// {
	//     id: 6,
	//     link: "/admin/demandPlaning",
	//     icon: <FaChartLine />,
	//     label: "Demand Planing",
	//     viewEnabled: true,
	//     submenu: [
	//         {
	//             id: 8.1,
	//             link: "/admin/forecast",
	//             label: "✾ Forecast Accuracy",
	//             subLabel:"FA",
	//             viewEnabled: true
	//         },
	//     ],
	// },
];

useEffect(() => {
	const temp = JSON.parse(localStorage?.getItem('isSidebarCollapsed') || 'null');
	if (temp !== null) {
		setIsSidebarCollapsed(temp);
	}
}, []);

const toggleCollapsed = () => {
	localStorage.setItem('isSidebarCollapsed', JSON.stringify(!isSidebarCollapsed));
	setIsSidebarCollapsed(!isSidebarCollapsed);
};

const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
	setAnchorProfileMenu(event.currentTarget);
};

const handleProfileMenuClose = () => {
	setAnchorProfileMenu(null);
};

const handleProfileClick = () => {
	navigate('/admin/profile');
	handleProfileMenuClose();
};

const handleLogoutClick = () => {
	setIsLogoutDialogOpen(true);
	handleProfileMenuClose();
};

const handleSubmenuToggle = (id: number) => {
	setExpandedSubmenu(expandedSubmenu === id ? null : id);
};

const handleLogoutConfirm = async () => {
	setIsLogoutDialogOpen(false);
	await logout();
	setCred(null);
	deleteAll();
	navigate('/');
	setAuthHeader(null);
};

const handleLogoutCancel = () => {
	setIsLogoutDialogOpen(false);
};


const logoSrc = theme === 'dark' ? LightLogo : DarkLogo;
const logoSrcMin = theme === 'dark' ? LightLogoMin : DarkLogoMin;

if(isLoading) return <center><h4>Loading...</h4></center>;
return (
	<div className={`w-full min-h-screen mx-auto  bg-[#f7f8f9] dark:bg-[#141729] text-gray-900 dark:text-white relative p-4 ${theme === 'dark' ? 'dark' : ''}`}
	>
		{/* Fixed Navbar */}
		<div className={`${isSidebarCollapsed ? 'w-[calc(100%-130px)]' : 'w-[calc(100%-290px)]'} fixed top-[20px] right-[12px] rounded-xl z-50 ${isNavbarTransparent ? 'bg-transparent text-black dark:text-white' : 'bg-[#7c5082] text-white dark:bg-[#202829] dark:text-white'} backdrop-blur-sm drop-shadow-sm px-4 py-4 justify-between items-center transition-all duration-300 ease-in-out flex`}>
			<div className="flex items-center">
				<button onClick={toggleCollapsed} className="mr-2">
					{isSidebarCollapsed ? <CgMenu size={24} /> : <CgMenuRightAlt size={24} />}
				</button>
			</div>
			<div className="flex items-center">
			<span className={`capitalize text-lg font-bold ${isNavbarTransparent ? 'text-[#7c5082]' : 'text-white'}`}>Welcome! <span className={`text-sm  ${isNavbarTransparent ? 'text-[#7c5082]' : 'text-white'}`}>{user?.name}</span></span>
				{/* Profile */}
				<Tooltip title='Settings'>
					<IconButton aria-label="show profile" onClick={handleProfileMenuOpen}>
						<MdSettings className={`w-6 ${isNavbarTransparent ? 'text-black' : 'text-white'} dark:text-white`} />
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={anchorProfileMenu}
					open={Boolean(anchorProfileMenu)}
					onClose={handleProfileMenuClose}
					slotProps={{
						paper: {
							style: {
								backgroundColor: theme === 'dark' ? '#202829' : 'white',
								color: theme === 'dark' ? 'white' : 'black',
							},
						},
					}}
				>
					<MenuItem
						sx={{
							'&:hover': {
								color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
								backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
							},
						}}
					>
						<div className='flex justify-end items-center w-[90px] drop-shadow-xl rounded-3xl'>
							<FormControlLabel
								control={<MaterialUISwitch checked={theme === 'dark'} onChange={handleThemeChange} />}
								label={
									<Typography
										sx={{
											fontFamily: 'Poppins',
											fontSize: '13px',
											fontWeight: '700',
										}}
									>
										{theme === 'dark' ? 'Dark' : 'Light'}
									</Typography>
								} 
								sx={{ m: 1 }}
							/>
						</div>
					</MenuItem>
					<MenuItem
						onClick={handleProfileClick}
						sx={{
							'&:hover': {
								color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
								backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
							},
						}}
					>
						<ListItemIcon
							sx={{
								color: theme === 'dark' ? 'white' : 'black',
								transition: 'color 0.3s',
								'&:hover': {
									color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
								},
							}}
						>
							<MdAccountCircle />
						</ListItemIcon>
						<span className='font-Poppins font-semibold text-sm'>Profile</span>
					</MenuItem>
					<MenuItem
						onClick={handleLogoutClick}
						sx={{
							'&:hover': {
								color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
								backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
							},
						}}
					>
						<ListItemIcon
							sx={{
								color: theme === 'dark' ? 'white' : 'black',
								transition: 'color 0.3s',
								'&:hover': {
									color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
								},
							}}
						>
							<MdExitToApp />
						</ListItemIcon>
						<span className='font-Poppins font-semibold text-sm'>Logout</span>
					</MenuItem>
				</Menu>
			</div>
		</div>
		{/* Logout Confirmation Dialog */}
			<Dialog
				open={isLogoutDialogOpen}
				onClose={handleLogoutCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						backgroundColor: theme === 'dark' ? '#333533' : 'white',
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					style={{
						fontFamily: 'Poppins, sans-serif',
						color: 'white',
						fontSize: '20px',
						textAlign: 'center',
						backgroundColor: '#202829',
					}}
				>
					{"Confirm Logout?"}
				</DialogTitle>
				<DialogContent
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						marginTop: 8
					}}
				>
					<DialogContentText
						id="alert-dialog-description"
						style={{
							fontFamily: 'Poppins, sans-serif',
							color: theme === 'dark' ? 'white' : 'black',
							fontSize: '20px',
							textAlign: 'center',
						}}
					>
						Are you sure you want to logout?
					</DialogContentText>
				</DialogContent>
			<div className='flex justify-center items-center'>
			<DialogActions>
				<button onClick={handleLogoutConfirm} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
					<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#7c5082] opacity-[3%]"></span>
					<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#7c5082] opacity-100 group-hover:-translate-x-8"></span>
					<span className="relative w-full text-left text-[#7c5082] transition-colors duration-200 text-sm ease-in-out group-hover:text-white">Yes</span>
					<span className="absolute inset-0 border-2 border-[#7c5082] rounded-lg"></span>
				</button>
				<button onClick={handleLogoutCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
					<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-gray-500 opacity-[3%]"></span>
					<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-gray-500 opacity-100 group-hover:-translate-x-8"></span>
					<span className="relative w-full text-left text-gray-500 transition-colors duration-200 text-sm ease-in-out group-hover:text-white">No</span>
					<span className="absolute inset-0 border-2 border-gray-500 rounded-lg"></span>
				</button>
			</DialogActions>
			</div>
		</Dialog>


		{/* Sidebar */}
		<div className={`${isSidebarCollapsed ? 'w-[90px]' : 'w-[250px]'} fixed h-[calc(100%-40px)] p-2 flex flex-col bg-transparent text-black dark:text-white rounded-xl top-[20px] z-10 transition-all duration-300 ease-in-out`}>
		{/* Logo or brand */}
			<div className="flex flex-col items-center border-b-[0.5px] border-gray-400 justify-center">
			<img src={logoSrc} alt="Logo" className={`${isSidebarCollapsed ? 'hidden' : 'w-[160px] h-[100px] py-2'}`} />
			<img src={logoSrcMin} alt="Logo" className={`${isSidebarCollapsed ? 'w-[50px] h-[40px] p-2' : 'hidden'}`} />
			</div>
			{/* Navigation links */}
			<ul className='flex flex-col px-2 my-6 overflow-y-auto'>
				{links.map((link) => (
					<div key={`${link.id}`}>
						{link.submenu ? (
							<div>
								{/* minimised sidebar */}
								<Tooltip title={link.label} placement="right" arrow>
									<div
										onClick={(e) => {
											e.preventDefault();
											handleSubmenuToggle(link.id);
										}}
										className={`
											${isSidebarCollapsed ? 'mb-2 px-2 py-2 justify-center rounded-md cursor-pointer hover:bg-white' : ' px-6 mb-2 py-2 rounded-md hover:bg-white cursor-pointer'} 
											items-center transition-all duration-300 ease-in-out flex w-full hover:text-[#7c5082] hover:bg-white
											${location.pathname.startsWith(link.link ?? '') ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
										`}
									>
										{link.icon}
										<span className={`${isSidebarCollapsed ? 'hidden' : 'ml-2'}  text-sm w-full hover:text-[#7c5082] font-extralight font-Poppins`}>
											{link.label}
										</span>
										<IconButton
											size="small"
											onClick={(e) => {
												e.preventDefault();
												handleSubmenuToggle(link.id);
											}}
										>
											{expandedSubmenu === link.id ? <MdExpandLess className='w-full text-xs text-black dark:text-white hover:text-black dark:hover:text-black' /> : <MdExpandMore className='w-full text-xs text-black dark:text-white hover:text-black dark:hover:text-black' />}
										</IconButton>
									</div>
								</Tooltip>
								<Collapse in={expandedSubmenu === link.id} timeout="auto" unmountOnExit>
									<ul className={`${isSidebarCollapsed ? 'ml-2' : 'ml-6'}`}>
										{link.submenu.map((submenu, index) => (
											<li key={index} className="mb-2">
												<Tooltip title={submenu.label} placement="right" arrow>
													<NavLink
														to={submenu.link}
														className={`
															 flex items-center w-full px-4 py-2 rounded-md hover:text-[#7c5082] hover:bg-white
															transition-all duration-300 ease-in-out 
															${location.pathname === submenu.link ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
														`}
													>
														<span className={`${isSidebarCollapsed ? ' text-xs font-extralight font-Poppins' : ' text-sm font-extralight font-Poppins'}`}>
															{isSidebarCollapsed ? submenu.subLabel : submenu.label}
														</span>
													</NavLink>
												</Tooltip>
											</li>
										))}
									</ul>
								</Collapse>
							</div>
						) : (
							// expanded sidebar
							<Tooltip title={link.label} placement="right" arrow>
								<NavLink
									to={link.link ?? "/"}
									className={`
										${isSidebarCollapsed ? ' mb-2 px-2 py-2 rounded-md justify-center' : ' px-6 mb-2 py-2 rounded-md hover:bg-white'} 
										flex items-center w-full transition-all duration-300 ease-in-out  hover:text-[#7c5082]
										${location.pathname === link.link ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
									`}
								>
									{link.icon}
									<span className={`${isSidebarCollapsed ? 'hidden' : 'ml-2'} text-sm font-extralight font-Poppins`}>
										{link.label}
									</span>
								</NavLink>
							</Tooltip>
						)}
					</div>
				))}
			</ul>
		</div>
		{/* Main Content */}
		<main className={`flex-1 transition-all mt-4 duration-300 ease-in-out ${isSidebarCollapsed ? 'ml-[105px]' : 'ml-[275px]'}  ${!isNavbarTransparent && 'blur-background'}`}>
			<Routes>
				<Route index element={<Dashboard pageTitle='Dashboard' isSidebarCollapsed={isSidebarCollapsed} />} />
				<Route path="profile" element={<Profile />} />
				
				{/* role */}
				<Route path="roles" element={<Roles pageTitle='Roles' cred={cred} />} />
                    <Route path="roles/add" element={<AddRole />} />
					<Route path="roles/:id/edit" element={<EditRole />} />
					
					{/* user */}
                    <Route path="users" element={<Users pageTitle='Users' cred={cred} />} />
                    <Route path="users/add" element={<AddUser />} />
					<Route path="users/:id/edit" element={<EditUser />} />
					
					{/* company */}
                    <Route path="companies" element={<Companies pageTitle='Companies' cred={cred} />} />
                    <Route path="companies/add" element={<AddCompany />} />
					<Route path="companies/:id/edit" element={<EditCompany />} />
					
					{/* item-type */}
                    <Route path="itemTypes" element={<ItemTypes pageTitle='Item Types' cred={cred} />} />
                    <Route path="itemTypes/add" element={<AddItemTypes />} />
					<Route path="itemTypes/:id/edit" element={<EditItemTypes />} />
					
					{/* brandCategory */}
                    <Route path="categoryBrands" element={<BrandCategories pageTitle='Brand Categories' cred={cred} />} />
                    <Route path="categoryBrands/add" element={<AddBrandCategories />} />
					<Route path="categoryBrands/:id/edit" element={<EditBrandCategories />} />
					
					{/* productCategory */}
                    <Route path="productCategories" element={<ProductCategories pageTitle='Product Categories' cred={cred} />} />
					<Route path="productCategories/add" element={<AddProductCategories />} />
					<Route path="productCategories/:id/edit" element={<EditProductCategories />} />
					
					{/* brand */}
                    <Route path="brands" element={<Brands pageTitle='Brands' cred={cred} />} />
                    <Route path="brands/add" element={<AddBrand />} />
					<Route path="brands/:id/edit" element={<EditBrand />} />
					
					{/* uom */}
                    <Route path="units" element={<Uoms pageTitle='Units of Measurement' cred={cred} />} />
                    <Route path="units/add" element={<AddUom />} />
					<Route path="units/:id/edit" element={<EditUom />} />
					
					{/* item */}
                    <Route path="items" element={<Items pageTitle='Items' cred={cred} />} />
                    <Route path="items/add" element={<AddItem />} />
					<Route path="items/:id/edit" element={<EditItem />} />
					<Route path="items/:id/view" element={<ViewItem />} />
					
					{/* classification */}
                    <Route path="classifications" element={<Classifications pageTitle='Classifications' cred={cred} />} />
                    <Route path="classifications/add" element={<AddClassification />} />
					<Route path="classifications/:id/edit" element={<EditClassification />} />
					
					{/* warehouse */}
                    <Route path="warehouses" element={<Warehouses pageTitle='Warehouses' cred={cred} />} />
                    <Route path="warehouses/add" element={<AddWarehouse />} />
					<Route path="warehouses/:id/edit" element={<EditWarehouse />} />
					
					{/* sales-category */}
                    <Route path="sales-categories" element={<SalesCategories pageTitle='Sales Categories' cred={cred} />} />
                    <Route path="sales-categories/add" element={<AddSalesCategory />} />
					<Route path="sales-categories/:id/edit" element={<EditSalesCategory />} />
					
					{/* channel */}
                    <Route path="channels" element={<Channels pageTitle='Channels' cred={cred} />} />
                    <Route path="channels/add" element={<AddChannel />} />
					<Route path="channels/:id/edit" element={<EditChannel />} />
					
					{/* customer */}
                    <Route path="customers" element={<Customers pageTitle='Customers' cred={cred} />} />
                    <Route path="customers/add" element={<AddCustomer />} />
					<Route path="customers/:id/edit" element={<EditCustomer />} />
					
					{/* vendor */}
                    <Route path="vendors" element={<Vendors pageTitle='Vendors' cred={cred} />} />
                    <Route path="vendors/add" element={<AddVendor />} />
					<Route path="vendors/:id/edit" element={<EditVendor />} />
					
					{/* country */}
                    <Route path="countries" element={<Countries pageTitle='Countries' cred={cred} />} />
                    <Route path="countries/add" element={<AddCountry />} />
					<Route path="countries/:id/edit" element={<EditCountry />} />
					
					{/* inbound */}
                    <Route path="inbounds" element={<Inbounds pageTitle='Inbound' cred={cred} />} />
                    <Route path="inbounds/add" element={<AddInbound />} />
					
					{/* outbound */}
                    <Route path="outbounds" element={<OutBounds pageTitle='OutBound' cred={cred} />} />
                    <Route path="outbounds/add" element={<AddOutbound />} />
					
					{/* size */}
                    <Route path="sizes" element={<Sizes pageTitle='Sizes' cred={cred} />} />
                    <Route path="sizes/add" element={<AddSize />} />
					<Route path="sizes/:id/edit" element={<EditSize />} />
					
					{/* currency */}
                    <Route path="currencies" element={<Currencies pageTitle='Currencies' cred={cred} />} />
					<Route path="currencies/add" element={<AddCurrency />} />
					<Route path="currencies/:id/edit" element={<EditCurrency />} />
					
					{/* transfer */}
                    <Route path="transfers" element={<Transfers pageTitle='Transfers' cred={cred} />} />
                    <Route path="transfers/add" element={<AddTransfer />} />

                    {/* demand */}
                    <Route path="averageDemand" element={<AvgDemand pageTitle='Average Demands' cred={cred} />} />
                    <Route path="averageDemand/add" element={<AddAvgDemand />} />

					{/* shipment types */}
                    <Route path="shipment-types" element={<ShipmentTypes pageTitle='Shipment Types' cred={cred} />} />
					<Route path="shipment-types/add" element={<AddShipmentType />} />
					<Route path="shipment-types/:id/edit" element={<EditShipmentType />} />

					{/* Brokers */}
					<Route path="brokers" element={<Brokers pageTitle="Brokers" cred={cred} />} />
					<Route path="brokers/add" element={<AddBroker />} />
					<Route path="brokers/:id/edit" element={<EditBroker />} />

					{/* Shipments */}
					<Route path="shipments" element={<Shipments pageTitle="Shipments" cred={cred} />} />
					<Route path="shipments/add" element={<AddShipment />} />
					<Route path="shipments/:id/edit" element={<EditShipment />} />
                </Routes>
            </main>
        </div>
    );
};
