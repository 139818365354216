import { getCustomers, updateCustomer, deleteCustomer, addCustomer } from "../../lib/api";
import { updateCustomers } from "../features/customerSlice";
import type { AppDispatch } from "../store";
import { CustomerModel } from "../../types/models";
import { CustomerPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetCustomers() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<CustomerModel>(params, getCustomers);
		dispatch(updateCustomers({ list: result.list, count: result.count, page }));
	}
}

export function useAddCustomer() {
	return async function (body: CustomerPayload, cb: () => void) {
		if (await mutationApi(addCustomer, body, "Customer Added", "Unable to Add Customer")){
			cb();
		}
	}
}

export function useEditCustomer() {
	return async function (id: number, body: CustomerPayload, cb: () => void) {
		if (await mutationApi(updateCustomer, { id, body }, "Customer Updated", "Unable to Update Customer")){
			cb();
		}
	}
}

export function useDeleteCustomer() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteCustomer, "Customer")) {
			cb();
		}
	}
}
