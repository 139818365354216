import { TextField } from '@mui/material';
import { styles } from "../../lib/styles";
import { CompanyPayload } from "../../types/payloads";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: CompanyPayload;
};

export default function CompanyForm(props: Props) {
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Company Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Company Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
