import { MenuItem, Select, TextField } from '@mui/material';
import { getThemeSelectStyle, styles } from "./styles";
import { FormContent } from "../types/common";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export function renderFormField(item: FormContent, theme: any) {
	switch(item.inputType) {
		case "select": return (
			<Select
				name={item.name}
				value={item.value}
				onChange={item.onChange}
				displayEmpty
				disabled={item.isDisabled}
				inputProps={{className: 'dark:text-white text-black',}}
				className='bg-slate-100 dark:bg-[#202829]'
				sx={getThemeSelectStyle(theme)}
			>
				{item.options?.map((elem) => (
					<MenuItem key={elem.id} value={elem.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
						{elem[item.field as keyof typeof elem]}
					</MenuItem>
				))}
			</Select>
		);
		case "date": return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MobileDatePicker
					value={item.value ? dayjs(item.value) : null}
					onChange={(date: dayjs.Dayjs | null) => item.onDateChange ? item?.onDateChange(date, item.name) : () => {}}
					format="MM/DD/YYYY"
					disabled={item.isDisabled}
					slots={{
						textField: (params) => (
							<TextField
								{...params}
								placeholder="Select Date"
								sx={styles.datepickerText}
								className="bg-slate-100 dark:bg-[#202829] rounded-lg"
							/>
						),
					}}
				/>
			</LocalizationProvider>
		);
		default: return (
			<TextField
				name={item.name}
				placeholder={item.placeholder}
				variant="outlined"
				value={item.value}
				disabled={item.isDisabled}
				onChange={item.onChange}
				inputProps={{ className: 'dark:text-white text-black' }}
				sx={styles.inputStyle}
				className='bg-slate-100 dark:bg-[#202829] rounded-lg'
			/>
		);
	}
} 
