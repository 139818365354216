import { TextField } from '@mui/material';
import { styles } from "../../lib/styles";
import { ClassificationPayload } from "../../types/payloads";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: ClassificationPayload;
};

export default function ClassificationForm(props: Props) {
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Classification<span className="text-red-400">*</span></h2>
					<TextField
						name="classification"
						placeholder="Classification"
						variant="outlined"
						value={props.formData?.classification}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black', maxLength: 10 }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Target Days Cover<span className="text-red-400">*</span></h2>
					<TextField
						name="targetDaysCover"
						placeholder="Target Days Cover"
						variant="outlined"
						value={props.formData?.targetDaysCover}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>	
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
