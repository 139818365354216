import { getItems, updateItem, deleteItem, addItem } from "../../lib/api";
import { updateItems } from "../features/itemSlice";
import type { AppDispatch } from "../store";
import { ItemModel } from "../../types/models";
import { ItemPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetItems() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ItemModel>(params, getItems);
		dispatch(updateItems({ list: result.list, count: result.count, page }));
	}
}

export function useAddItem() {
	return async function (body: ItemPayload, cb: () => void) {
		if (await mutationApi(addItem, body, "Item Added", "Unable to Add Item")){
			cb();
		}
	}
}

export function useEditItem() {
	return async function (id: number, body: ItemPayload, cb: () => void) {
		if (await mutationApi(updateItem, { id, body }, "Item Updated", "Unable to Update Item")){
			cb();
		}
	}
}

export function useDeleteItem() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteItem, "Item")) {
			cb();
		}
	}
}
