import { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { LoginResult, ShipmentModel } from '../../types/models';
import { TableItem } from "../../types/common";
import { Params } from "../../redux/hooks/apiHooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import DeleteDialog from "../common/DeleteDialog";
import TableRowItem from "../common/TableRowItem";
import { handleDelete } from "../../lib/handlers";
import { useDeleteShipment, useGetShipments } from '../../redux/hooks/shipmentHooks';
import Import from "../Import";
import { getBoxStyles } from "../../lib/styles";
import { getExportURL } from "../../lib/api";

const headers: string[] = [
	"Type of Shipment", "B.L. Number Shipment Number", "SAP PO Number",
	"Manual PO", "Loading Date", "Broker", "Vendor", "Consignee", "Brand", "SKU Code",
	"UOM", "Number of Pieces",
	"Number of Cases", "Pick-up/Delivery", "Destination", "Vessel ETD", "Ready for Booking Date", "SAP PO Creation Date",
	"SAP PO Approval Date", "SAP GRPO", "Shipping Documents Submission to F&A", "Currency", "SAP PO Value in Currency",
	"Importation Cost (in PHP)", "Importation Cost as of PO Value", "",
];
const limit = 10;

export default function Shipments({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
	const info = useAppSelector((state) => state.shipments);
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useAppDispatch();
    const navigate = useNavigate();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [openImport, setOpenImport] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
	const [tableData, setTableData] = useState<TableItem[][]>([]);
	const { theme } = useTheme();
	const [params] = useState<Params>({ offset: 0, limit });
	const getShipments = useGetShipments();
	const deleteShipment = useDeleteShipment();
	const [searchForm] = useState({
        company: "", broker: "", shipmentType: "",
    });

	useEffect(() => {
		if (!info.list) {
			getData();
		} else if (info?.list?.length){
			prepareData(info.list);
		}
	}, [info]);

	const getData = () => getShipments(dispatch, params);
	const prepareData = (items: ShipmentModel[]) => {
		const data: TableItem[][] = [];
		let counter = 0;
		for (const item of items) {
			data.push([
				{ key: `${counter}-1`, value: item?.shipmentTypeName ?? "", valueType: "text" },
				{ key: `${counter}-2`, value: item?.shipmentNumber ?? "", valueType: "text" },	
				{ key: `${counter}-3`, value: item?.sapPONumber ?? "", valueType: "text" },
				{ key: `${counter}-4`, value: item?.manualPO ?? "", valueType: "text" },	
				{ key: `${counter}-5`, value: item?.loadingDate ?? "", valueType: "date" },
				{ key: `${counter}-6`, value: item?.brokerCode ?? "", valueType: "text" },	
				{ key: `${counter}-7`, value: item?.vendorName ?? "", valueType: "text" },
				{ key: `${counter}-8`, value: item?.companyName ?? "", valueType: "text" },	
				{ key: `${counter}-9`, value: item?.brandName ?? "", valueType: "text" },
				{ key: `${counter}-10`, value: item?.itemCode ?? "", valueType: "text" },	
				{ key: `${counter}-11`, value: item?.uomGroup ?? "", valueType: "text" },
				{ key: `${counter}-12`, value: item?.quantity ?? "", valueType: "text" },
				{ key: `${counter}-13`, value: item?.cases ?? "", valueType: "text" },	
				{ key: `${counter}-14`, value: item?.pickupType ?? "", valueType: "text" },
				{ key: `${counter}-15`, value: item?.warehouseCode ?? "", valueType: "text" },	
				{ key: `${counter}-16`, value: item?.vesselETD ?? "", valueType: "date" },
				{ key: `${counter}-17`, value: item?.readyForBookingDate ?? "", valueType: "date" },	
				{ key: `${counter}-18`, value: item?.sapPOCreationDate ?? "", valueType: "date" },
				{ key: `${counter}-19`, value: item?.sapPOApprovalDate ?? "", valueType: "date" },	
				{ key: `${counter}-20`, value: item?.sapGrpo ?? "", valueType: "text" },
				{ key: `${counter}-21`, value: item?.documentsSubmissionDate ?? "", valueType: "date" },	
				{ key: `${counter}-22`, value: item?.currencyName ?? "", valueType: "text" },
				{ key: `${counter}-23`, value: item?.sapPOValue ?? "", valueType: "text" },	
				{ key: `${counter}-24`, value: item?.importationCost ?? "", valueType: "text" },
				{ key: `${counter}-25`, value: item?.importationCostPOValue ?? "", valueType: "text" },
				{ key: `${counter}-26`, id: item.id, value: "", valueType: "action", actions: [
					{ actionType: "edit", handler: (id: number) => navigate(`${id}/edit`) },
					{ actionType: "delete", handler: handleDeleteClick },
				] },
			]);
			counter += 1;
		}
		setTableData(data);
	};

	const handlePageChange = (page: number) => {
		const offset = (page - 1) * limit
		getShipments(dispatch, { offset, limit });
		setCurrentPage(page);
	};

	const handleAddClick = () => navigate("add");
    const handleDeleteClick = (id: number) => handleDelete(id, true, setDeleteId, setIsDeleteDialogOpen);
    const handleDeleteConfirm = () => {
        if (deleteId) deleteShipment(deleteId, getData);
        setIsDeleteDialogOpen(false);
    };
	const exportHandler = () => {
		let url: string = getExportURL("shipments");
		url += `
			?broker=${searchForm?.broker ?? ""}&shipmentType=${searchForm?.shipmentType ?? ""}&
			company=${searchForm?.company ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={headers}
                totalRows={info.count}
                colSpan={headers.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={handlePageChange}
				reload={getData}
				cred={cred}
				componentType="shipment"
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
            >
               {tableData.map((items: TableItem[]) => (
					<TableRowItem key={items[0].key} items={items} />
				))} 
            </CommonTable>
			<DeleteDialog
				isOpen={isDeleteDialogOpen}
				onClose={() => handleDelete(null, false, setDeleteId, setIsDeleteDialogOpen)}
				onConfirm={handleDeleteConfirm}
				title="Confirm Delete"
				text="Are you sure you want to delete this Shipment?"
				theme={theme}
			/>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={getBoxStyles(theme)} >
					<Import fileType="shipments" refresh={getData} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
