import React, { useState } from 'react';
import Excel from "../assets/excel.png";
import { bulkUpload, getTemplateURL } from "../lib/api";
import { showNotification } from "../lib/helpers";

type Props = {
	fileType: string;
	refresh: () => void;
	onClose: () => void;
};

export default function Import({ fileType, refresh, onClose }: Props) {
	const [file, setFile] = useState<File | null>(null);

  	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    	const selectedFile = e.target.files?.[0];
    	if (selectedFile) {
      		setFile(selectedFile);
    	}
  	};

  	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (file) {
			const form = new FormData();
			form.append("file", file);
			const response = await bulkUpload(form, fileType);
			if (response?.success) {
				showNotification("success", `Successfully uploaded ${fileType}`);
				refresh();
				onClose();
			} else {
				showNotification("error", response?.message ?? `Unable to upload ${fileType}`);
			}
    	}
  	};

	const templateHandler = () => {
		const url: string = getTemplateURL(fileType);
		window.open(url, "_blank");
	};

  	const handleChooseFileClick = () => {
    	const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    	fileInput?.click();
  	};

  	return (
    	<main className="bg-white p-4 rounded-lg shadow-lg">
			<div className="flex justify-between">
				<span className="text-sm font-Poppins font-base text-black">
        			UPLOAD {fileType?.toUpperCase()} FILE <span className='text-red-400'>*</span>
				</span>
				<button 
					onClick={templateHandler} 
					className="text-xs font-Poppins font-base text-white rounded bg-blue-400 hover:bg-white hover:text-blue-400 border border-blue-400 px-2 py-2 drop-shadow-sm"
				>
					DOWNLOAD {fileType?.toUpperCase()} TEMPLATE
				</button>
			</div>
      		<form onSubmit={handleSubmit} className="mt-4">
        		<input 
          			type="file" 
          			accept=".xls,.xlsx" 
          			onChange={handleFileChange} 
          			className="hidden"
        		/>
        		<button 
					type="button" 
					className="mr-2 bg-[#191919] text-white px-2 py-2 rounded border border-black hover:bg-white hover:text-black"
					onClick={handleChooseFileClick}
				>
          			Choose File
        		</button>
        		{file && (
        			<div className="mt-4">
          				<p className="dark:text-white">Selected file: {file.name}</p>
          				<img src={Excel} alt="Preview of Excel file" className="mt-2 w-[40px]" />
        			</div>
      			)}
				<div className="flex justify-between">
					<button type="submit" className="mt-4 add-btn">Upload</button>
					<button
						onClick={onClose}
						className="mt-4 bg-[#7c5082] hover:bg-white border border-[#7c5082] hover:text-[#7c5082] text-white font-semibold py-2 px-4 rounded-lg"
					>
						Cancel
					</button>
				</div>
      		</form>
    	</main>
  	);
}
