import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VendorModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<VendorModel> = {
	list: null,
	page: 0,
	count: 0,
};

const vendorSlice = createSlice({
	name: "vendors", initialState,
	reducers: {
		updateVendors: (state, action: PayloadAction<SlicePayload<VendorModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateVendors } = vendorSlice.actions;
export default vendorSlice.reducer;
