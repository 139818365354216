import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { BrokerPayload, BrokerShipmentPayload } from '../../types/payloads';
import { validate } from '../../lib/validation';
import { ShipmentTypeModel } from '../../types/models';
import { getShipmentTypes } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import BrokerForm from "./BrokerForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetBrokers, useAddBroker } from "../../redux/hooks/brokerHooks";
import { updateForm, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function AddBroker() {
    const [formData, setFormData] = useState<BrokerPayload>({
        name: "", code: "", shipmentTypes: [],
    });
    const [shipmentTypes, setShipmentTypes ] = useState<ShipmentTypeModel[]>([]);
    const [shipmentList, setShipmentList] = useState("");
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addBroker = useAddBroker();
	const getBrokers = useGetBrokers();
	const info = useAppSelector((state) => state.brokers);

    useEffect(() => {
		fetchList(
			[getShipmentTypes],
			queryParams,
			[setShipmentTypes],
		)
    }, []);

    const handleSubmit = (e: any) => {
		e.preventDefault();
		if (shipmentList?.length > 0) {
			const shipment: BrokerShipmentPayload[] = [];
			const shipmentSplit = shipmentList.split(",");
			for (let item of shipmentSplit) {
				shipment.push({
					shipmentType: sanitizeValue(Number(item)),
				});
			}
			formData.shipmentTypes = shipment;
			if (validate(formData, "broker")) {
				const updateParams: Params = {
					offset: (info.page - 1) * 10,
					limit: 10,
				};
				addBroker(formData, () => {
					getBrokers(dispatch, updateParams);
					navigate(-1);
				});
			}
			else {
				showNotification("error", "Missing Required Field");
			}
		} else {
			showNotification("error", "Person In Charge Cannot be Empty");
		}
	};

	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Broker</span>
			</div>
    		<BrokerForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				shipmentTypes={shipmentTypes}
				setShipmentList={setShipmentList}
				shipmentList={shipmentList}
			/>
    	</main>
    );
};
