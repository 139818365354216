import { getShipmentTypes, updateShipmentType, deleteShipmentType, addShipmentType } from "../../lib/api";
import { updateShipmentTypes } from "../features/shipmentTypeSlice";
import type { AppDispatch } from "../store";
import { ShipmentTypeModel } from "../../types/models";
import { ShipmentTypePayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetShipmentTypes() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ShipmentTypeModel>(params, getShipmentTypes);
		dispatch(updateShipmentTypes({ list: result.list, count: result.count, page }));
	}
}

export function useAddShipmentType() {
	return async function (body: ShipmentTypePayload, cb: () => void) {
		if (await mutationApi(addShipmentType, body, "Shipment Type Added", "Unable to Add Shipment Type")){
			cb();
		}
	}
}

export function useEditShipmentType() {
	return async function (id: number, body: ShipmentTypePayload, cb: () => void) {
		if (await mutationApi(updateShipmentType, { id, body }, "Shipment Type Updated", "Unable to Update Shipment Type")){
			cb();
		}
	}
}

export function useDeleteShipmentType() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteShipmentType, "Shipment Type")) {
			cb();
		}
	}
}
