import { getSizes, updateSize, deleteSize, addSize } from "../../lib/api";
import { updateSizes } from "../features/sizeSlice";
import type { AppDispatch } from "../store";
import { SizeModel } from "../../types/models";
import { SizePayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetSizes() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<SizeModel>(params, getSizes);
		dispatch(updateSizes({ list: result.list, count: result.count, page }));
	}
}

export function useAddSize() {
	return async function (body: SizePayload, cb: () => void) {
		if (await mutationApi(addSize, body, "Size Added", "Unable to Add Size")){
			cb();
		}
	}
}

export function useEditSize() {
	return async function (id: number, body: SizePayload, cb: () => void) {
		if (await mutationApi(updateSize, { id, body }, "Size Updated", "Unable to Update Size")){
			cb();
		}
	}
}

export function useDeleteSize() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteSize, "Size")) {
			cb();
		}
	}
}
