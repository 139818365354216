import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { useTheme } from '../../ThemeContext';
import { styles } from "../../lib/styles";
import ShipmentTypeForm from "./ShipmentTypeForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetShipmentTypes, useEditShipmentType } from "../../redux/hooks/shipmentTypeHooks";
import { updateForm, fetchOne } from "../../lib/handlers";
import { ShipmentTypePayload } from "../../types/payloads";
import { getShipmentType } from "../../lib/api";

export default function EditShipmentType() {
    const [formData, setFormData] = useState<ShipmentTypePayload>({ name: "" });
    const navigate = useNavigate();
    const params = useParams();
    const { theme } = useTheme();
   	const dispatch = useAppDispatch();
	const updateShipmentType = useEditShipmentType();
	const getShipmentTypes = useGetShipmentTypes();
	const info = useAppSelector((state) => state.shipmentTypes);

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getShipmentType, setFormData);
		}
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<ShipmentTypePayload>(formData, "shipmentType")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateShipmentType(sanitizeValue(params.id),formData, () => {
				getShipmentTypes(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	}; 

    return (
        <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Shipment Type</span>
			</div>
			<ShipmentTypeForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				theme={theme}
			/>
        </main>
    );
}
