import React, { useEffect, useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useTheme } from '../ThemeContext';
import { getProfile, updatePassword } from '../lib/api';
import { ApiSingleResponse, UserModel } from '../types/models';
import { UserPayload } from '../types/payloads';
import { showNotification } from '../lib/helpers';
import { validate } from "../lib/validation";

const Profile: React.FC = () => {
    const { theme } = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [reEnterPasswordVisible, setReEnterPasswordVisible] = useState(false);
    const [user, setUser] = useState<UserModel | null>(null);
    const [loading, setLoading] = useState(true);

	const labelStyle = `text-base ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'} font-bold`;
	const textStyle = "text-sm font-light font-Poppins";
	const btnStyle = `
		mt-6 px-6 py-2 ${theme === 'light' ? 'bg-[#7c5082] text-white' : 'bg-[#7c5082] text-white'}
		text-sm rounded font-Poppins shadow-lg hover:bg-[#aa77b1] transition duration-300
	`;
	const inputStyle = `
		w-full px-4 py-2 border ${theme === 'light' ? 'border-gray-300' : 'border-gray-600 bg-slate-500'}
		rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500
	`;
	const inputLabelStyle = `text-xs font-medium ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`;

    useEffect(() => {
        const fetchProfile = async () => {
            try {
				const response: ApiSingleResponse<UserModel> = await getProfile();
                if (response.success) {
                    setUser(response.result);
                } else {
                    showNotification("error", "Failed to fetch profile data");
                }
                setLoading(false);
            } catch (err) {
                showNotification("error", "Failed to fetch profile data");
                setLoading(false);
            }
        };
        fetchProfile();
    }, []);

	const handlePasswordUpdate = async () => {
		const payload: UserPayload = {
			permissions: user?.permissions ?? [],
			password: oldPassword,
			newPassword: newPassword,
		};
		if (validate(payload, "changePassword")) {
			if (newPassword === confirmPassword) {
				try {
					const response = await updatePassword(payload);
					if (response.success) {
						showNotification("success", "Password updated successfully!");
						setShowModal(false);
						setOldPassword('');
						setNewPassword('');
						setConfirmPassword('');
					} else {
						const errorMessage = response.message || "Unable to change password";
						showNotification("error", errorMessage);
					}
				} catch (err) {
					console.error('Error updating password:', err);
					showNotification("error", "Failed to update password. Please try again.");
				}
			} else {
				showNotification("error", "Passwords do not match!");
			}
		} else {
			showNotification("error", "Missing Required Fields");
		}
    };

    if (loading) return <p>Loading...</p>;
    return (
        <div className={`flex flex-col items-center mt-40 p-8 ${theme === 'light' ? 'bg-white' : 'bg-gray-800'} rounded-lg shadow-lg max-w-md mx-auto`}>
            <h2 className={`mt-4 text-3xl font-Poppins font-bold ${theme === 'light' ? 'text-gray-800' : 'text-white'}`}>{user?.name}</h2>
            <div className='flex flex-col py-4'>
                <p className={labelStyle}>User ID : <span className={textStyle}>{user?.userId}</span></p>
                <p className={labelStyle}>Email : <span className={textStyle}>{user?.email}</span></p>
				<p className={labelStyle}>Role : <span className={textStyle}>{user?.roleName}</span></p>
            </div>
            <button onClick={() => setShowModal(true)} className={btnStyle}>
                Change Password
            </button>
			{showModal && (
                <div className={`fixed inset-0 flex justify-center items-center ${theme === 'dark' ? 'bg-black bg-opacity-60' : 'bg-black bg-opacity-40'}`}>
                    <div className={`bg-white p-6 rounded-lg shadow-lg w-96 transition-transform transform scale-100 animate-modal ${theme === 'dark' ? 'bg-gray-800' : ''}`}>
                        <h3 className={`text-xl font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>Change Password</h3>
                        <div className="mb-2">
                            <p className={`text-xs font-medium ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>Enter Old Password <span className='text-red-600'>*</span></p>
                        </div>
                        <div className="relative mb-4">
                            <input 
                                type={oldPasswordVisible ? 'text' : 'password'} 
                                placeholder="Old Password" 
                                value={oldPassword} 
                                onChange={(e) => setOldPassword(e.target.value)} 
                                className={inputStyle} 
                            />
                            <button 
                                type="button" 
                                onClick={() => setOldPasswordVisible(!oldPasswordVisible)} 
                                className="absolute right-3 top-3 text-gray-500 dark:text-white"
                            >
                                {oldPasswordVisible ? <HiEyeOff className="w-4 h-4" /> : <HiEye className="w-4 h-4" />}
                            </button>
                        </div>

                        <div className="mb-2">
                            <p className={inputLabelStyle}>Enter New Password <span className='text-red-600'>*</span></p>
                        </div>
                        <div className="relative mb-4">
                            <input 
                                type={newPasswordVisible ? 'text' : 'password'} 
                                placeholder="New Password" 
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)} 
                                className={inputStyle} 
                            />
                            <button 
                                type="button" 
                                onClick={() => setNewPasswordVisible(!newPasswordVisible)} 
                                className="absolute right-3 top-3 text-gray-500 dark:text-white"
                            >
                                {newPasswordVisible ? <HiEyeOff className="w-4 h-4" /> : <HiEye className="w-4 h-4" />}
                            </button>
                        </div>

                        <div className="mb-2">
                            <p className={inputLabelStyle}>Re-Enter New Password <span className='text-red-600'>*</span></p>
                        </div>
                        <div className="relative mb-4">
                            <input 
                                type={reEnterPasswordVisible ? 'text' : 'password'} 
                                placeholder="Re-enter Password" 
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                className={inputStyle} 
                            />
                            <button 
                                type="button" 
                                onClick={() => setReEnterPasswordVisible(!reEnterPasswordVisible)} 
                                className="absolute right-3 top-3 text-gray-500 dark:text-white"
                            >
                                {reEnterPasswordVisible ? <HiEyeOff className="w-4 h-4" /> : <HiEye className="w-4 h-4" />}
                            </button>
                        </div>
                        <div className='flex gap-4'>
                            <button 
                                onClick={handlePasswordUpdate} 
                                className={`w-full px-6 py-2 ${theme === 'light' ? 'bg-[#7c5082] text-white' : 'bg-[#7c5082] text-white'} rounded text-sm shadow-lg hover:bg-[#aa77b1] transition duration-300`}
                            >
                                Save
                            </button>
                            <button 
                                 onClick={() => setShowModal(false)}
								 className={`w-full px-6 py-2 ${theme === 'light' ? 'bg-gray-500 text-white' : 'bg-gray-500 text-white'} rounded text-sm shadow-lg hover:bg-red-400 transition duration-300`}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
