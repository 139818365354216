import { getBrokers, updateBroker, deleteBroker, addBroker } from "../../lib/api";
import { updateBrokers } from "../features/brokerSlice";
import type { AppDispatch } from "../store";
import { BrokerModel } from "../../types/models";
import { BrokerPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetBrokers() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<BrokerModel>(params, getBrokers);
		dispatch(updateBrokers({ list: result.list, count: result.count, page }));
	}
}

export function useAddBroker() {
	return async function (body: BrokerPayload, cb: () => void) {
		if (await mutationApi(addBroker, body, "Broker Added", "Unable to Add Broker")){
			cb();
		}
	}
}

export function useEditBroker() {
	return async function (id: number, body: BrokerPayload, cb: () => void) {
		if (await mutationApi(updateBroker, { id, body }, "Broker Updated", "Unable to Update Broker")){
			cb();
		}
	}
}

export function useDeleteBroker() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteBroker, "Broker")) {
			cb();
		}
	}
}
