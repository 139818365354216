import { TextField, MenuItem, Select } from '@mui/material';
import { styles, getThemeSelectStyle } from "../../lib/styles";
import { ProductCategoryPayload } from "../../types/payloads";
import { ItemTypeModel, BrandCategoryModel } from "../../types/models";
import { useTheme } from "@mui/material/styles";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: ProductCategoryPayload;
	itemTypes: ItemTypeModel[];
	brandCategories: BrandCategoryModel[];
};

export default function ProductCategoryForm(props: Props) {
	const theme = useTheme();
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Item Type<span className="text-red-400">*</span></h2>
					<Select
						name="itemType"
						value={`${props.formData?.itemType}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.itemTypes?.map((item: ItemTypeModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Brand Category<span className="text-red-400">*</span></h2>
					<Select
						name="brandCategory"
						value={`${props.formData?.brandCategory}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.brandCategories?.map((item: BrandCategoryModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Product Category Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Product Category Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
