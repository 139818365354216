import { getCountries, updateCountry, deleteCountry, addCountry } from "../../lib/api";
import { updateCountries } from "../features/countrySlice";
import type { AppDispatch } from "../store";
import { CountryModel } from "../../types/models";
import { CountryPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetCountries() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<CountryModel>(params, getCountries);
		dispatch(updateCountries({ list: result.list, count: result.count, page }));
	}
}

export function useAddCountry() {
	return async function (body: CountryPayload, cb: () => void) {
		if (await mutationApi(addCountry, body, "Country Added", "Unable to Add Country")){
			cb();
		}
	}
}

export function useEditCountry() {
	return async function (id: number, body: CountryPayload, cb: () => void) {
		if (await mutationApi(updateCountry, { id, body }, "Country Updated", "Unable to Update Country")){
			cb();
		}
	}
}

export function useDeleteCountry() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteCountry, "Country")) {
			cb();
		}
	}
}
