import { getUoms, updateUom, deleteUom, addUom } from "../../lib/api";
import { updateUoms } from "../features/uomSlice";
import type { AppDispatch } from "../store";
import { UomModel } from "../../types/models";
import { UomPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetUoms() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<UomModel>(params, getUoms);
		dispatch(updateUoms({ list: result.list, count: result.count, page }));
	}
}

export function useAddUom() {
	return async function (body: UomPayload, cb: () => void) {
		if (await mutationApi(addUom, body, "Uom Added", "Unable to Add Uom")){
			cb();
		}
	}
}

export function useEditUom() {
	return async function (id: number, body: UomPayload, cb: () => void) {
		if (await mutationApi(updateUom, { id, body }, "Uom Updated", "Unable to Update Uom")){
			cb();
		}
	}
}

export function useDeleteUom() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteUom, "Uom")) {
			cb();
		}
	}
}
