import { configureStore } from "@reduxjs/toolkit";
import RoleReducer from "./features/roleSlice";
import UserReducer from "./features/userSlice";
import CompanyReducer from "./features/companySlice";
import WarehouseReducer from "./features/warehouseSlice";
import SalesCategoryReducer from "./features/salesCategorySlice";
import ChannelReducer from "./features/channelSlice";
import CustomerReducer from "./features/customerSlice";
import CountryReducer from "./features/countrySlice";
import VendorReducer from "./features/vendorSlice";
import CurrencyReducer from "./features/currencySlice";
import ItemTypeReducer from "./features/itemTypeSlice";
import BrandCategoryReducer from "./features/brandCategorySlice";
import ProductCategoryReducer from "./features/productCategorySlice";
import BrandReducer from "./features/brandSlice";
import UomReducer from "./features/uomSlice";
import ClassificationReducer from "./features/classificationSlice";
import SizeReducer from "./features/sizeSlice";
import ItemReducer from "./features/itemSlice";
import ShipmentTypeReducer from "./features/shipmentTypeSlice";
import ShipmentReducer from "./features/shipmentSlice";
import BrokerReducer from "./features/brokerSlice";
import InboundReducer from "./features/inboundSlice";
import OutbounReducer from "./features/outboundSlice";

export const store = configureStore({
	reducer: {
		roles: RoleReducer,
		users: UserReducer,
		companies: CompanyReducer,
		warehouses: WarehouseReducer,
		salesCategories: SalesCategoryReducer,
		channels: ChannelReducer,
		customers: CustomerReducer,
		countries: CountryReducer,
		vendors: VendorReducer,
		currencies: CurrencyReducer,
		itemTypes: ItemTypeReducer,
		brandCategories: BrandCategoryReducer,
		productCategories: ProductCategoryReducer,
		brands: BrandReducer,
		uoms: UomReducer,
		classifications: ClassificationReducer,
		sizes: SizeReducer,
		items: ItemReducer,
		shipmentTypes: ShipmentTypeReducer,
		brokers: BrokerReducer,
		shipments: ShipmentReducer,
		inbounds: InboundReducer,
		outbounds: OutbounReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

