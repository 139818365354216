import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetRoles, useEditRole } from "../../redux/hooks/roleHooks";
import { updateForm, fetchOne } from "../../lib/handlers";
import { RolePayload } from "../../types/payloads";
import { MetadataModel } from "../../types/models";
import { getRole, getMetadata } from "../../lib/api";
import RoleForm from './RoleForm';

export default function EditRole() {
    const [formData, setFormData] = useState<RolePayload>({ name: "", permissions: [] });
    const navigate = useNavigate();
    const params = useParams();
   	const dispatch = useAppDispatch();
	const updateRole = useEditRole();
	const getRoles = useGetRoles();
	const info = useAppSelector((state) => state.roles);

	useEffect(() => {
		if(!params.id || formData.name) return;
		fetchOne(sanitizeValue(params.id), getRole, (data: RolePayload) => {
			const modules = new Set<number>();
			for (const item of data?.permissions) {
				modules.add(item.module);
			}
			const list = [...data.permissions];
			fetchOne(0, getMetadata, (result: MetadataModel) => {
				for (const item of result?.modules) {
					if (!modules.has(item.id)) {
						list.push({
							module: item.id,
							moduleName: item.name,
							hasAdd: 0, hasEdit: 0,
							hasView: 0, hasDelete: 0,
						});
					}
				}
				data.permissions = list;
				setFormData(data);
			});
		});
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<RolePayload>(formData, "role") && formData?.permissions?.length > 0) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateRole(sanitizeValue(params.id), formData, () => {
				getRoles(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	}; 

    return (
        <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Role</span>
			</div>
			<RoleForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				setFormData={setFormData}
			/>
        </main>
    );
}
