import { styles } from "../../lib/styles";

type Props = {
	onCancel: () => void;
};

export default function FormFooter(props: Props) {
	return (
		<div className='flex justify-center space-x-12 items-center'>
			<button className={styles.formBtnContainer}>
				<span className={styles.formBtnSuccessTranslate}></span>
				<span className={styles.formBtnSuccessTransition}></span>
				<span className={styles.formBtnSuccessText}>Save</span>
				<span className={styles.formBorderSuccess}></span>
			</button>
			<button type="button" onClick={props.onCancel} className={styles.formBtnContainer}>
				<span className={styles.formBtnDangerTranslate}></span>
				<span className={styles.formBtnDangerTransition}></span>
				<span className={styles.formBtnDangerText}>Cancel</span>
				<span className={styles.formBorderDanger}></span>
			</button>
		</div>
	);
}
