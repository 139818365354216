import { useEffect, useState } from 'react';
import {
	Modal, Box,
} from '@mui/material';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { ItemModel, LoginResult } from '../../types/models';
import { TableItem } from "../../types/common";
import { useGetItems, useDeleteItem } from "../../redux/hooks/itemHooks";
import { Params } from "../../redux/hooks/apiHooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import DeleteDialog from "../common/DeleteDialog";
import TableRowItem from "../common/TableRowItem";
import { handleDelete } from "../../lib/handlers";
import Import from "../Import";
import { getBoxStyles } from "../../lib/styles";
import { getExportURL } from "../../lib/api";

const headers: string[] = [
	"Item Type", "Brand Category", "Product Category", "Brand Code",
    "Parent Code", "Item Code", "Item Name", "Item Category",
    "UOM", "Size", "Target Days Cover", "Purchased Price",
    "Currency", "Landed COGS", "Suggested Retail Price",
    "Net Sales", "COGS %", "GP %", "COGS To SRP", "",
];
const limit = 10;

export default function Items({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
	const info = useAppSelector((state) => state.items);
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [openImport, setOpenImport] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
	const [tableData, setTableData] = useState<TableItem[][]>([]);
	const { theme } = useTheme();
	const [params] = useState<Params>({ offset: 0, limit });
	const [searchForm] = useState({
        name: "", itemCode: "", parentCode: "",
        brandCategory: "", productCategory: "",
    });
	const getItems = useGetItems();
	const deleteItem = useDeleteItem();

	useEffect(() => {
		if (!info.list) {
			getData();
		} else if (info?.list?.length){
			prepareData(info.list);
		}
	}, [info]);

	const getData = () => getItems(dispatch, params);
	const prepareData = (items: ItemModel[]) => {
		const data: TableItem[][] = [];
		let counter = 0;
		for (const item of items) {
			data.push([
				{ key: `${counter}-1`, value: item?.itemTypeName ?? "", valueType: "text" },
				{ key: `${counter}-2`, value: item?.brandCategoryName ?? "", valueType: "text" },
				{ key: `${counter}-3`, value: item?.productCategoryName ?? "", valueType: "text" },
				{ key: `${counter}-4`, value: item?.brandCode ?? "", valueType: "text" },
				{ key: `${counter}-5`, value: item?.parentCode ?? "", valueType: "text" },
				{ key: `${counter}-6`, value: item?.itemCode ?? "", valueType: "text" },
				{ key: `${counter}-7`, value: item?.itemName ?? "", valueType: "text" },
				{ key: `${counter}-8`, value: item?.itemCategory ?? "", valueType: "text" },
				{ key: `${counter}-9`, value: item?.uomGroup ?? "", valueType: "text" },
				{ key: `${counter}-10`, value: item?.sizeName ?? "", valueType: "text" },
				{ key: `${counter}-11`, value: item?.classificationName ?? "", valueType: "text" },
				{ key: `${counter}-12`, value: item?.purchasePrice ?? "", valueType: "text" },
				{ key: `${counter}-13`, value: item?.currencyName ?? "", valueType: "text" },
				{ key: `${counter}-14`, value: item?.landedCogs ?? "", valueType: "text" },
				{ key: `${counter}-15`, value: item?.suggestedRetailPrice ?? "", valueType: "text" },
				{ key: `${counter}-16`, value: item?.netSales ?? "", valueType: "text" },
				{ key: `${counter}-17`, value: item?.cogs ?? "", valueType: "text" },
				{ key: `${counter}-18`, value: item?.gp ?? "", valueType: "text" },
				{ key: `${counter}-19`, value: item?.cogsToSrp ?? "", valueType: "text" },
				{ key: `${counter}-20`, id: item.id, value: "", valueType: "action", actions: [
					{ actionType: "view", handler: (id: number) => navigate(`${id}/view`) },
					{ actionType: "edit", handler: (id: number) => navigate(`${id}/edit`) },
					{ actionType: "delete", handler: handleDeleteClick },
				] },
			]);
			counter += 1;
		}
		setTableData(data);
	};

	const handlePageChange = (page: number) => {
		const offset = (page - 1) * limit
		getItems(dispatch, { offset, limit });
		setCurrentPage(page);
	};

	const handleAddClick = () => navigate("add");
    const handleDeleteClick = (id: number) => handleDelete(id, true, setDeleteId, setIsDeleteDialogOpen);
    const handleDeleteConfirm = () => {
        if (deleteId) deleteItem(deleteId, getData);
        setIsDeleteDialogOpen(false);
    };

	const exportHandler = () => {
		let url: string = getExportURL("items");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&parentCode=${searchForm?.parentCode ?? ""}&
			brandCategory=${searchForm?.brandCategory ?? ""}&productCategory=${searchForm?.productCategory ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={headers}
                totalRows={info.count}
                colSpan={headers.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={handlePageChange}
				reload={getData}
				cred={cred}
				componentType="item"
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
            >
               {tableData.map((items: TableItem[]) => (
					<TableRowItem key={items[0].key} items={items} />
				))} 
            </CommonTable>
			<DeleteDialog
				isOpen={isDeleteDialogOpen}
				onClose={() => handleDelete(null, false, setDeleteId, setIsDeleteDialogOpen)}
				onConfirm={handleDeleteConfirm}
				title="Confirm Delete"
				text="Are you sure you want to delete this Item?"
				theme={theme}
			/>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={getBoxStyles(theme)} >
					<Import fileType="items" refresh={getData} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
