import { getBrands, updateBrand, deleteBrand, addBrand } from "../../lib/api";
import { updateBrands } from "../features/brandSlice";
import type { AppDispatch } from "../store";
import { BrandModel } from "../../types/models";
import { BrandPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetBrands() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<BrandModel>(params, getBrands);
		dispatch(updateBrands({ list: result.list, count: result.count, page }));
	}
}

export function useAddBrand() {
	return async function (body: BrandPayload, cb: () => void) {
		if (await mutationApi(addBrand, body, "Brand Added", "Unable to Add Brand")){
			cb();
		}
	}
}

export function useEditBrand() {
	return async function (id: number, body: BrandPayload, cb: () => void) {
		if (await mutationApi(updateBrand, { id, body }, "Brand Updated", "Unable to Update Brand")){
			cb();
		}
	}
}

export function useDeleteBrand() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteBrand, "Brand")) {
			cb();
		}
	}
}
