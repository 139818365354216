import { TextField } from '@mui/material';
import { styles } from "../../lib/styles";
import { WarehousePayload } from "../../types/payloads";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: WarehousePayload;
};

export default function WarehouseForm(props: Props) {
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Warehouse Code<span className="text-red-400">*</span></h2>
					<TextField
						name="code"
						placeholder="Warehouse Code"
						variant="outlined"
						value={props.formData?.code}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Description<span className="text-red-400">*</span></h2>
					<TextField
						name="description"
						placeholder="Description"
						variant="outlined"
						value={props.formData?.description}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Location<span className="text-red-400">*</span></h2>
					<TextField
						name="location"
						placeholder="Location"
						variant="outlined"
						value={props.formData?.location}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
