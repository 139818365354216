import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemTypeModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<ItemTypeModel> = {
	list: null,
	page: 0,
	count: 0,
};

const itemTypeSlice = createSlice({
	name: "itemTypes", initialState,
	reducers: {
		updateItemTypes: (state, action: PayloadAction<SlicePayload<ItemTypeModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateItemTypes } = itemTypeSlice.actions;
export default itemTypeSlice.reducer;
