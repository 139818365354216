import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VendorPayload } from '../../types/payloads';
import { CountryModel } from "../../types/models";
import { showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getCountries } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import VendorForm from "./VendorForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetVendors, useAddVendor } from "../../redux/hooks/vendorHooks";
import { updateForm, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function AddVendor() {
    const [formData, setFormData] = useState<VendorPayload>({
		name: "", code: "", country: 0,
  	});
	const [countries, setCountries] = useState<CountryModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addVendor = useAddVendor();
	const getVendors = useGetVendors();
	const info = useAppSelector((state) => state.vendors);

	useEffect(() => {
		fetchList([getCountries], queryParams, [setCountries]);
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<VendorPayload>(formData, "vendor")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addVendor(formData, () => {
				getVendors(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Vendor</span>
			</div>
			<VendorForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				countries={countries}
			/>
    	</main>
  	);
};
