import { getRoles, updateRole, deleteRole, addRole } from "../../lib/api";
import { updateRoles } from "../features/roleSlice";
import type { AppDispatch } from "../store";
import { RoleModel } from "../../types/models";
import { RolePayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetRoles() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<RoleModel>(params, getRoles);
		dispatch(updateRoles({ list: result.list, count: result.count, page }));
	}
}

export function useAddRole() {
	return async function (body: RolePayload, cb: () => void) {
		if (await mutationApi(addRole, body, "Role Added", "Unable to Add Role")){
			cb();
		}
	}
}

export function useEditRole() {
	return async function (id: number, body: RolePayload, cb: () => void) {
		if (await mutationApi(updateRole, { id, body }, "Role Updated", "Unable to Update Role")){
			cb();
		}
	}
}

export function useDeleteRole() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteRole, "Role")) {
			cb();
		}
	}
}
