import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InboundModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<InboundModel> = {
	list: null,
	page: 0,
	count: 0,
};

const inboundSlice = createSlice({
	name: "inbounds", initialState,
	reducers: {
		updateInbounds: (state, action: PayloadAction<SlicePayload<InboundModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateInbounds } = inboundSlice.actions;
export default inboundSlice.reducer;
