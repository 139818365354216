import { isEmpty } from "./helpers";

const fields = {
    company : ["name"],
    itemType : ["name"],
    brandCategory : ["name"],
    productCategory : ["name", "itemType", "brandCategory"],
    brand : ["code", "name", "company"],
    uom : ["uomGroup", "multiplier"],
    role : ["name", "permissions"],
    user : ["name", "userId", "email", "role"],
    item : [
        "itemType", "brandCategory", "productCategory", "brand", "parentCode", "itemCode", "classification",
        "itemName", "uom", "purchasePrice", "currency", "suggestedRetailPrice", "size","itemCategory"
    ],
    classification : ["classification", "targetDaysCover"],
    warehouse : ["code", "description", "location"],
    salesCategory : ["name"],
    channel : ["name"],
    customer : ["name", "salesCategory", "channel", "personInCharge"],
    country : ["name"],
    vendor : ["code", "name", "country"],
    inbound : ["warehouse", "item", "poNumber", "poQuantity", "uom", "vendor"],
    outbound : ["warehouse", "item", "poNumber", "poQuantity", "uom", "customer"],
    size : ["size"],
    currency : ["currency", "conversionRate"],
    transfer : ["fromWarehouse", "toWarehouse", "item", "poQuantity", "itrNumber", "uom"],
    demand : ["brand", "item", "month", "year", "firstMonth", "secondMonth", "thirdMonth"],
	demandUpdate: ["id", "firstMonth", "secondMonth", "thirdMonth"],
    login : ["email", "password"],
	changePassword: ["password", "newPassword"],
	shipmentType: ["name"],
	broker: ["code", "name", "shipmentTypes"],
    shipment:["shipmentType", "shipmentNumber", "sapPONumber", "manualPO", "loadingDate",
            "broker", "vendor", "company", "brand", "item", "uom", "quantity", "pickupType",
            "warehouse", "vesselETD", "readyForBookingDate", "sapPOCreationDate", "sapPOApprovalDate",
            "sapGrpo", "documentsSubmissionDate", "sapPOValue", "importationCost",
	],
}

export function validate<T>(body: T, entity: string): boolean {
	for (const key of fields[entity as keyof typeof fields]) {
		if (isEmpty(body[key as keyof typeof body])) {
			return false;
		}
	}
	return true;
};

export function validateList<T>(arr: T[], entity: string): boolean {
	for (const item of arr) {
		if (!validate(item, entity)) {
			return false;
		}
	}
	return true;
}
