import { useEffect, useState } from 'react';
import CommonTable from '../CommonTable';
import {
	TableCell, TableRow, Modal, FormControl,
	Select, MenuItem, TextField,
} from '@mui/material';
import { queryConfigs } from '../../react-query/queryConfig';
import { DashboardItem, StockItem } from '../../lib/models';
import { useTheme } from '../../ThemeContext';
import { useGetQuery } from '../../react-query/queryHook';
import { sanitizeValue } from '../../lib/helpers';
import { FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { Chip } from '@mui/material';
import DashboardCard from "./DashboardCard";
import StockList from "./StockList";

const dashboardHeaderNames: string[] = [
	"Brand Code", "Parent Code", "Item Code",
	"Item Name", "Item Category", "Pieces per case",
	"RC", "Head Office", "Laloma", "Triplef", "Shoshin",
	"BMIRK", "In Transit", "RTN", "Total Quantity", "Total Pieces",
	"Current Days cover", "Target Days cover", "Stock Status",
];
const fields: string[] = [
	"brandCode", "parentCode", "itemCode", "itemName", "itemCategory",
	"pcPerCase", "rcQuantity", "hoQuantity", "lamQuantity", "tvfQuantity",
	"shnQuantity", "bmkQuantity", "intQuantity", "rtnQuantity", "totalQuantity",
	"totalQuantityPc",
	"currentDaysCover", "targetDaysCover", "stockStatus",
]
const limit = 10;

const getChipColor = (stockStatus: string) => {
    switch (stockStatus) {
        case 'No Stock':
            return { label: 'No Stock', color: 'error', bgColor: '#9d0208' };
        case 'Low Stock':
            return { label: 'Low', color: 'warning', bgColor: '#c9a227' };
        case 'Over Stock':
            return { label: 'Over', color: 'primary', bgColor: '#003f88' };
        default:
            return { label: 'Normal', color: 'success', bgColor: '#2a9d8f' };
    }
};

export default function Dashboard({ isSidebarCollapsed, pageTitle }: { isSidebarCollapsed: boolean, pageTitle: string }) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const { queryFn: getDashboards, queryKey: dashboardKey } = queryConfigs.getDashboards;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemName");
    const [searchForm, setSearchForm] = useState({
		itemName: "", itemCode: "",
		brandCode: "",
    });
    const [dashboardList, setDashboardList] = useState<DashboardItem[]>([]);
	const [list, setList] = useState<DashboardItem[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [openNoStockModal, setOpenNoStockModal] = useState<boolean>(false);
    const [openLowStockModal, setOpenLowStockModal] = useState<boolean>(false);
	const [openOverStockModal, setOpenOverStockModal] = useState<boolean>(false);
	const [noStockItems, setNoStockItems] = useState<StockItem[]>([]);
	const [lowStockItems, setLowStockItems] = useState<StockItem[]>([]);
	const [overStockItems, setOverSTockItems] = useState<StockItem[]>([]);
    const { theme } = useTheme();

	const selectStyle = {
		width: '100%',
		fontFamily: 'Poppins, sans-serif',
		fontSize: '14px',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiSelect-select': {
			height: 'auto',
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiOutlinedInput-input': {
			padding: '10px 14px',
		},
	};

	const inputStyle = {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent',
		},
	};

    const { data: dashboards, refetch } = useGetQuery({
        key: dashboardKey,
        params: {
            itemName: searchForm?.itemName ?? "",
            brandCode: searchForm?.brandCode ?? "",
            itemCode: searchForm?.itemCode ?? "",
        },
        func: getDashboards,
	});

    useEffect(() => {
        if (dashboards) {
        	const list = dashboards?.result?.list as DashboardItem[];
			const lowStocks = dashboards?.result?.lowStock as StockItem[];
			const noStocks = dashboards?.result?.noStock as StockItem[];
			const overStocks = dashboards?.result?.overStock as StockItem[];
			setDashboardList(list);
			setList(list);
			setLowStockItems(lowStocks);
			setNoStockItems(noStocks);
			setOverSTockItems(overStocks);
        }
	}, [dashboards]);

	const changePage = (page: number) => {
        const counter = (page - 1) * limit;
        const list = dashboardList?.slice(counter, counter + limit);
        setList(list);
        setCurrentPage(page);
    };

    const handleSearchFilter = (e: any) => {
		const { name, value } = e.target;
		if (name === "searchType") {
			setSearchType(value);
		} else {
			setSearchValue(value);
		}
    };
    
	const handleSearch = () => {
        setSearchForm({
            itemName: searchType === "itemName" ? searchValue : "",
            brandCode: searchType === "brandCode" ? searchValue : "",
            itemCode: searchType === "itemCode" ? searchValue : "",
		});
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemName");
		setSearchForm({
			itemName: "", brandCode: "",
			itemCode: "",
		});
    };

    useEffect(() => {
        setSidebarCollapsed(isSidebarCollapsed);
    }, [isSidebarCollapsed]);

    useEffect(() => {
        localStorage.setItem('isSidebarCollapsed', JSON.stringify(sidebarCollapsed));
    }, [sidebarCollapsed]);

    const handleOpenNoStockModal = () => setOpenNoStockModal(!openNoStockModal);
    const handleCloseNoStockModal = () => setOpenNoStockModal(!openNoStockModal);
    const handleOpenLowStockModal = () => setOpenLowStockModal(!openLowStockModal);
    const handleCloseLowStockModal = () => setOpenLowStockModal(!openLowStockModal);
    const handleOpenOverStockModal = () => setOpenOverStockModal(!openOverStockModal);
    const handleCloseOverStockModal = () => setOpenOverStockModal(!openOverStockModal);

    return (
        <div className={`flex flex-col mt-[8%] w-full min-h-screen ${sidebarCollapsed ? 'ml-[0.5px]' : '-ml-[11px]'} py-12 relative transition-all duration-300 ease-in-out`}>
            <div className="flex flex-row w-full h-[100px] gap-4">
                <div className={`flex justify-center items-center px-2 w-[40%] space-x-2 z-30 bg-white dark:bg-[#101422] rounded-lg drop-shadow-lg`}>
                    <div>
                        <h2 className='text-xs font-Poppins mb-2'>Filter</h2>
                        <FormControl fullWidth>
                            <Select 
								name="searchType"
                                value={searchType} 
                                onChange={handleSearchFilter}
                                inputProps={{ className: 'dark:text-white text-black' }}
                                sx={selectStyle}
                                className='bg-slate-100 dark:bg-[#202829]'
                            >
                                <MenuItem value="itemName" style={{ fontFamily: 'Poppins' }}>Item Name</MenuItem>
                                <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                <MenuItem value="brandCode" style={{ fontFamily: 'Poppins' }}>Brand Code</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
						<div>
                            <h2 className='text-xs font-Poppins mb-2'>Enter To Search</h2>
							<TextField
								name={searchType}
								variant="outlined"
								placeholder="Enter to search"
								value={searchValue}
								onChange={handleSearchFilter}
								size="small"
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={inputStyle}
								className='bg-slate-100 dark:bg-[#202829] rounded-lg'
							/>
						</div>
                    </div>
                    <div className='flex space-x-2 mt-6'>
                        <button onClick={handleSearch} className="search-btn">
                            <FaSearch />
                        </button>
                        <button onClick={handleSearchClear} className="clear-btn">
                            <MdClear />
                        </button>
                    </div>
                </div>
				<DashboardCard
					clickHandler={handleOpenNoStockModal}
					title="NO STOCK"
					count={noStockItems?.length}
					cardType="noStock"
				/>
				<DashboardCard
					clickHandler={handleOpenLowStockModal}
					title="LOW STOCK"
					count={lowStockItems?.length}
					cardType="lowStock"
				/>
				<DashboardCard
					clickHandler={handleOpenOverStockModal}
					title="OVER STOCK"
					count={overStockItems?.length}
					cardType="overStock"
				/>
            </div>
            <div className="w-full overflow-hidden mx-auto relative">
                <CommonTable
					tableHeaders={dashboardHeaderNames}
					totalRows={sanitizeValue(dashboards?.result?.count)}
					pageTitle={pageTitle}
					colSpan={dashboardHeaderNames?.length}
					marginTop={8}
					componentType="dashboard"
					showReloadBtn={true}
					currentPage={currentPage}
					setCurrentPage={changePage}
					reload={refetch}
                >
                {list?.map((item: DashboardItem) => (
                    <TableRow key={item.id} style={{ height: '60px' }} className="bg-white dark:bg-[#101422]">
                        {fields?.map((key: string) => (
                            <TableCell key={key} align="center" sx={{ fontSize: '13px' }} className="capitalize text-gray-800 dark:text-white">
                                {key === 'stockStatus' ? (
                                    <Chip
                                        label={getChipColor(item.stockStatus).label}
                                        sx={{
                                            backgroundColor: getChipColor(item.stockStatus).bgColor,
                                            color: 'white',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                ) : (
                                    item[key as keyof typeof item]
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
                </CommonTable>
            </div>
            <Modal open={openNoStockModal} onClose={handleCloseNoStockModal}>
				<StockList
					title="No Stock List"
					items={noStockItems}
					theme={theme}
					handleClose={handleCloseNoStockModal}
				/> 
            </Modal>
            <Modal open={openLowStockModal} onClose={handleCloseLowStockModal}>
				<StockList
					title="Low Stock List"
					items={lowStockItems}
					theme={theme}
					handleClose={handleCloseLowStockModal}
				/>
            </Modal>
            <Modal open={openOverStockModal} onClose={handleCloseOverStockModal}>
				<StockList
					title="Over Stock List"
					items={overStockItems}
					theme={theme}
					handleClose={handleCloseOverStockModal}
				/>
            </Modal>
        </div>
    );
}
