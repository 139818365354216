import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductCategoryModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<ProductCategoryModel> = {
	list: null,
	page: 0,
	count: 0,
};

const productCategorySlice = createSlice({
	name: "productCategories", initialState,
	reducers: {
		updateProductCategories: (state, action: PayloadAction<SlicePayload<ProductCategoryModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateProductCategories } = productCategorySlice.actions;
export default productCategorySlice.reducer;
