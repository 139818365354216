import { TextField, MenuItem, Select } from '@mui/material';
import { styles, getThemeSelectStyle } from "../../lib/styles";
import { BrandPayload } from "../../types/payloads";
import { CompanyModel } from "../../types/models";
import { useTheme } from "@mui/material/styles";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: BrandPayload;
	companies: CompanyModel[];
};

export default function BrandForm(props: Props) {
	const theme = useTheme();
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Brand Code<span className="text-red-400">*</span></h2>
					<TextField
						name="code"
						placeholder="Brand Code"
						variant="outlined"
						value={props.formData?.code}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Brand Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Company<span className="text-red-400">*</span></h2>
					<Select
						name="company"
						value={`${props.formData?.company}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.companies?.map((item: CompanyModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Landed Cost Factor<span className="text-red-400">*</span></h2>
					<TextField
						name="landedCostFactor"
						placeholder="Landed Cost Factor"
						variant="outlined"
						value={props.formData?.landedCostFactor}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Retailer Margin (%)<span className="text-red-400">*</span></h2>
					<TextField
						name="retailerMargin"
						placeholder="Retailer Margin"
						variant="outlined"
						value={props.formData?.retailerMargin}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
