import { TextField, Autocomplete, Chip } from '@mui/material';
import { styles, getThemeAutocompleteChipStyle } from "../../lib/styles";
import { BrokerPayload } from "../../types/payloads";
import { ShipmentTypeModel } from "../../types/models";
import FormFooter from "../common/FormFooter";
import { useTheme as themeContext } from "../../ThemeContext";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: BrokerPayload;
	shipmentTypes: ShipmentTypeModel[];
	setShipmentList: (e: any) => void;
	shipmentList: string;
};

export default function BrokerForm(props: Props) {
	const { theme: themeCtx } = themeContext();

	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Broker Code<span className="text-red-400">*</span></h2>
					<TextField
						name="code"
						placeholder="Broker Code"
						variant="outlined"
						value={props.formData?.code}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Broker Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Broker Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Shipment Type<span className="text-red-400">*</span></h2>
					<Autocomplete
                    	multiple
                    	id="shipment-type-autocomplete"
                    	options={props.shipmentTypes}
                    	getOptionLabel={(option) => option.name}
						value={props.shipmentTypes?.filter(shipmentType => props.shipmentList?.includes(`${shipmentType?.id}`))}
                    	onChange={(event, value) => {
                        	props.setShipmentList(value.map((shipment: ShipmentTypeModel) => shipment.id).join(','));
                    	}}
                    	filterSelectedOptions
						renderTags={(value, getTagProps) =>
              				value.map((shipment, index) => (
                				<Chip
                  					label={shipment.name}
                  					{...getTagProps({ index })}
                  					className={`${
                    					themeCtx === 'dark' ? 'bg-[#2a2f3a]' : 'bg-gray-200'
									}`}
									sx={getThemeAutocompleteChipStyle(themeCtx)}
                				/>
              				))
            			}
                    	renderInput={(params) => (
                        	<TextField
                        		{...params}
                        		inputProps={{
                            		...params.inputProps,
                            		className: 'dark:text-white text-black',
                        		}}
							sx={styles.autocompleteInputStyle}
                        	className="bg-slate-100 dark:bg-[#202829] rounded-lg"
                        />
                    )}
                />	
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
