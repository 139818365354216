import { useState } from 'react';
import { styles } from "../../lib/styles";
import { ShipmentPayload } from "../../types/payloads";
import FormFooter from "../common/FormFooter";
import {
	BrandModel, BrokerModel, CompanyModel, CurrencyModel, ItemModel,
	ShipmentTypeModel, UomModel, VendorModel, WarehouseModel,
} from '@/types/models';
import { useTheme } from "@mui/material/styles";
import dayjs from 'dayjs';
import { renderFormField } from "../../lib/formHelper";
import { FormContent } from "../../types/common";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	handleItemChange: (e: any) => void;
	formData: ShipmentPayload;
	shipmentTypes: ShipmentTypeModel[];
	brokers: BrokerModel[];
	vendors: VendorModel[];
	companies: CompanyModel[];
	brands: BrandModel[];
	items: ItemModel[];
	uoms: UomModel[];
	warehouses: WarehouseModel[];
	currencies: CurrencyModel[];
};

const pickupTypes = [
	{ name: "Pick Up", id: "pick up" },
	{ name: "Delivery", id: "delivery" },
	{ name: "Other", id: "other" },
]

export default function ShipmentForm(props: Props) {
	const theme = useTheme();
	const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

	const handleDateChange = (newValue: dayjs.Dayjs | null, name: string) => {
		setSelectedDate(newValue);
		props.updateForm({ target: { name, value: newValue?.format('YYYY-MM-DD') || '' } });
	};

	const formContent: FormContent[] = [
		{
			id: 1, label: "Shipment Type", name: "shipmentType", inputType: "select",
			isRequired: true, value: props.formData.shipmentType ?? "",
			options: props.shipmentTypes, onChange: props.updateForm,
			field: "name",
		},
		{
			id: 2, label: "Shipment Number", name: "shipmentNumber",
			inputType: "text", isRequired: true, onChange: props.updateForm,
			value: props.formData?.shipmentNumber ?? "", placeholder: "Shipment Number",
		},
		{
			id: 3, label: "SAP PO Number", name: "sapPONumber", inputType: "text",
			isRequired: true, value: props.formData.sapPONumber ?? "",
			onChange: props.updateForm, placeholder: "SAP PO Number",
		},
		{
			id: 4, label: "Manual PO", name: "manualPO",
			inputType: "text", isRequired: true, onChange: props.updateForm,
			value: props.formData?.manualPO ?? "", placeholder: "Manual PO",
		},
		{
			id: 5, label: "Loading Date", name: "loadingDate",
			inputType: "date", isRequired: true, onDateChange: handleDateChange,
			value: props.formData.loadingDate,
		},
		{
			id: 6, label: "Broker", name: "broker", inputType: "select",
			isRequired: true, value: props.formData.broker ?? "",
			options: props.brokers, onChange: props.updateForm,
			field: "name",
		},
		{
			id: 7, label: "Vendor", name: "vendor", inputType: "select",
			isRequired: true, value: props.formData.vendor ?? "",
			options: props.vendors, onChange: props.updateForm,
			field: "name",
		},
		{
			id: 8, label: "Company", name: "company", inputType: "select",
			isRequired: true, value: props.formData.company ?? "",
			options: props.companies, onChange: props.updateForm,
			field: "name",
		},
		{
			id: 9, label: "Item", name: "item", inputType: "select",
			isRequired: true, value: props.formData.item ?? "",
			options: props.items, onChange: props.handleItemChange,
			field: "itemName",
		},
		{
			id: 10, label: "Brand", name: "brand", inputType: "select",
			isRequired: true, value: props.formData.brand ?? "",
			options: props.brands, onChange: props.updateForm,
			field: "name", isDisabled: true,
		},
		{
			id: 11, label: "Unit of Measurement", name: "uom", inputType: "select",
			isRequired: true, value: props.formData.uom ?? "",
			options: props.uoms, onChange: props.updateForm,
			field: "uomGroup", isDisabled: true,
		},
		{
			id: 12, label: "Quantity", name: "quantity", inputType: "text",
			isRequired: true, value: props.formData.quantity ?? "",
			onChange: props.updateForm,
		},
		{
			id: 13, label: "Pickup Type", name: "pickupType", inputType: "select",
			isRequired: true, value: props.formData.pickupType ?? "",
			options: pickupTypes, onChange: props.updateForm,
			field: "name",
		},
		{
			id: 14, label: "Warehouse", name: "warehouse", inputType: "select",
			isRequired: true, value: props.formData.warehouse ?? "",
			options: props.warehouses, onChange: props.updateForm,
			field: "code",
		},
		{
			id: 15, label: "Vessel ETD", name: "vesselETD", inputType: "date",
			isRequired: true, value: props.formData.vesselETD,
			onDateChange: handleDateChange,
		},
		{
			id: 16, label: "Ready for Booking Date", name: "readyForBookingDate",
			inputType: "date",
			isRequired: true, value: props.formData.readyForBookingDate,
			onDateChange: handleDateChange,
		},
		{
			id: 17, label: "SAP PO Creation Date", name: "sapPOCreationDate",
			inputType: "date",
			isRequired: true, value: props.formData.sapPOCreationDate,
			onDateChange: handleDateChange,
		},
		{
			id: 18, label: "SAP PO Approval Date", name: "sapPOApprovalDate",
			inputType: "date",
			isRequired: true, value: props.formData.sapPOApprovalDate,
			onDateChange: handleDateChange,
		},
		{
			id: 19, label: "SAP GRPO", name: "sapGrpo", inputType: "text",
			isRequired: true, value: props.formData.sapGrpo ?? "",
			onChange: props.updateForm, placeholder: "SAP GRPO",
		},
		{
			id: 20, label: "Shipping Documents Submission to F&A",
			name: "documentsSubmissionDate",
			inputType: "date",
			isRequired: true, value: props.formData.documentsSubmissionDate,
			onDateChange: handleDateChange,
		},
		{
			id: 21, label: "Currency", name: "currency", inputType: "select",
			isRequired: true, value: props.formData.currency ?? "",
			options: props.currencies, onChange: props.updateForm,
			field: "currency",
		},
		{
			id: 22, label: "SAP PO Value in Currency", name: "sapPOValue",
			inputType: "text",
			isRequired: true, value: props.formData.sapPOValue ?? "",
			onChange: props.updateForm, placeholder: "SAP PO Value in Currency",
		},
		{
			id: 23, label: "Importation Cost", name: "importationCost",
			inputType: "text", isRequired: true,
			value: props.formData.importationCost ?? "",
			onChange: props.updateForm, placeholder: "Importation Cost",
		},
	];

	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				{formContent?.map((item: FormContent) => (
					<div key={item.id}>
						<h2 className={styles.labelStyle}>
							{item.label}
							{item.isRequired && <span className="text-red-400">*</span>}
						</h2>
						{renderFormField(item, theme)}
					</div>
				))}
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
