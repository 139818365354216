import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<CustomerModel> = {
	list: null,
	page: 0,
	count: 0,
};

const customerSlice = createSlice({
	name: "customers", initialState,
	reducers: {
		updateCustomers: (state, action: PayloadAction<SlicePayload<CustomerModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateCustomers } = customerSlice.actions;
export default customerSlice.reducer;
