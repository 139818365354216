import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<UserModel> = {
	list: null,
	page: 0,
	count: 0,
};

const userSlice = createSlice({
	name: "users", initialState,
	reducers: {
		updateUsers: (state, action: PayloadAction<SlicePayload<UserModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateUsers } = userSlice.actions;
export default userSlice.reducer;
