import { getVendors, updateVendor, deleteVendor, addVendor } from "../../lib/api";
import { updateVendors } from "../features/vendorSlice";
import type { AppDispatch } from "../store";
import { VendorModel } from "../../types/models";
import { VendorPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetVendors() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<VendorModel>(params, getVendors);
		dispatch(updateVendors({ list: result.list, count: result.count, page }));
	}
}

export function useAddVendor() {
	return async function (body: VendorPayload, cb: () => void) {
		if (await mutationApi(addVendor, body, "Vendor Added", "Unable to Add Vendor")){
			cb();
		}
	}
}

export function useEditVendor() {
	return async function (id: number, body: VendorPayload, cb: () => void) {
		if (await mutationApi(updateVendor, { id, body }, "Vendor Updated", "Unable to Update Vendor")){
			cb();
		}
	}
}

export function useDeleteVendor() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteVendor, "Vendor")) {
			cb();
		}
	}
}
