import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChannelPayload } from '../../types/payloads';
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getChannel } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import ChannelForm from "./ChannelForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetChannels, useEditChannel } from "../../redux/hooks/channelHooks";
import { updateForm, fetchOne } from "../../lib/handlers";

export default function EditChannel() {
    const [formData, setFormData] = useState<ChannelPayload>({
		name: "",
  	});
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateChannel = useEditChannel();
	const getChannels = useGetChannels();
	const info = useAppSelector((state) => state.channels);
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getChannel, setFormData);
		}
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<ChannelPayload>(formData, "channel")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateChannel(sanitizeValue(params.id), formData, () => {
				getChannels(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Channel</span>
			</div>
			<ChannelForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
			/>
    	</main>
  	);
};
