import { getUsers, updateUser, deleteUser, addUser } from "../../lib/api";
import { updateUsers } from "../features/userSlice";
import type { AppDispatch } from "../store";
import { UserModel } from "../../types/models";
import { UserPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetUsers() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<UserModel>(params, getUsers);
		dispatch(updateUsers({ list: result.list, count: result.count, page }));
	}
}

export function useAddUser() {
	return async function (body: UserPayload, cb: () => void) {
		if (await mutationApi(addUser, body, "User Added", "Unable to Add User")){
			cb();
		}
	}
}

export function useEditUser() {
	return async function (id: number, body: UserPayload, cb: () => void) {
		if (await mutationApi(updateUser, { id, body }, "User Updated", "Unable to Update User")){
			cb();
		}
	}
}

export function useDeleteUser() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteUser, "User")) {
			cb();
		}
	}
}
