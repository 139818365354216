import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShipmentTypeModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<ShipmentTypeModel> = {
	list: null,
	page: 0,
	count: 0,
};

const shipmentTypeSlice = createSlice({
	name: "shipmentTypes", initialState,
	reducers: {
		updateShipmentTypes: (state, action: PayloadAction<SlicePayload<ShipmentTypeModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateShipmentTypes } = shipmentTypeSlice.actions;
export default shipmentTypeSlice.reducer;
