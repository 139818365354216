import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemPayload, BundleItemPayload } from '../../lib/payload';
import {
	BrandModel, BrandCategoryModel, ClassificationModel,
	CurrencyModel, ItemModel, ItemTypeModel, ProductCategoryModel,
	SizeModel, UomModel, BundleItemModel,
} from '../../types/models';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import {
	getBrandCategories, getBrands, getClassifications, getCurrencies, getItem,
	getItemTypes, getProductCategories, getSizes, getUoms, getBundleItems,
	getItems, deleteBundleItem,
} from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import ItemForm from "./ItemForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetItems, useEditItem } from "../../redux/hooks/itemHooks";
import { updateForm, fetchList, fetchOne } from "../../lib/handlers";
import { styles } from "../../lib/styles";

const queryParams = { offset: 0, limit: 1000 };
export default function EditItem() {
    const [formData, setFormData] = useState<ItemPayload>({});
    const [itemTypes, setItemTypes] = useState<ItemTypeModel[]>([]);
	const [brandCategories, setBrandCategories] = useState<BrandCategoryModel[]>([]);
    const [productCategories, setProductCategories] = useState<ProductCategoryModel[]>([]);
    const [brands, setBrands] = useState<BrandModel[]>([]);
    const [uoms, setUoms] = useState<UomModel[]>([]);
    const [classifications, setClassifications ] = useState<ClassificationModel[]>([]);
    const [sizes, setSizes] = useState<SizeModel[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyModel[]>([]);
	const [items, setItems] = useState<ItemModel[]>([]);
    const params = useParams ();
   	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateItem = useEditItem();
	const refetchItems = useGetItems();
	const info = useAppSelector((state) => state.items); 

    useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getItem, initItem);
			fetchList(
				[
					getItemTypes, getBrandCategories, getProductCategories, getBrands,
					getUoms, getClassifications, getSizes, getCurrencies, getItems,
				],
				queryParams,
				[
					setItemTypes, setBrandCategories, setProductCategories, setBrands,
					setUoms, setClassifications, setSizes, setCurrencies, setItems,
				]
			);
        }
    }, [params]);

    const initItem = async (itemData: ItemPayload) => {
        try {
			const bundleItems: BundleItemPayload[] = [];
			if (itemData?.itemCategory?.toUpperCase() === "BUNDLE") {
				const bundleResponse = await getBundleItems(sanitizeValue(params.id));
				if (bundleResponse?.success) {
					const list = bundleResponse?.result?.list as BundleItemModel[] ?? [];
					for (const item of list) {
						bundleItems.push({
							packItemId: item.packItemId,
							itemId: item.itemId,
							packItemCode: item.packItemCode,
							itemCode: item.itemCode,
							quantity: item.quantity,
						});
					}
				}
			}
			setFormData({
				itemType: itemData.itemType,
				brandCategory: itemData.brandCategory,
				productCategory: itemData.productCategory,
				brand: itemData.brand,
				parentCode: itemData.parentCode,
				itemCode: itemData.itemCode,
				itemName: itemData.itemName,
				classification: itemData.classification,
				size:itemData.size,
				uom: itemData.uom,
				purchasePrice: itemData.purchasePrice,
				currency: itemData.currency,
				suggestedRetailPrice: itemData.suggestedRetailPrice,
				itemCategory: itemData.itemCategory,
				items: bundleItems,
			});
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item details");
        }
    };

	const handleAddItem = () => {
		setFormData(prev => ({
			...formData,
			items: [...(prev?.items ?? []), { itemCode: "", quantity: 0 }],
		}));
	};

	const handleItemChange = (index: number, e: any) => {
		const { name, value } = e.target;
		const itemList = [...(formData?.items ?? [])];
		if (name === "itemData") {
			const itemDataSplit = value?.split("_");
			const itemId: number = sanitizeValue(itemDataSplit[0]);
			const itemCode: string = itemDataSplit[1];
			itemList[index] = {
				...itemList[index],
				itemId, itemCode,
				packItemCode: formData?.itemCode,
			};
		} else {
            itemList[index] = {
                ...itemList[index],
                quantity: sanitizeValue(value),
            }
        }
        setFormData({
            ...formData,
            items: itemList,
        });
	};
  
    const handleSubmit = (e: any) => {
        e.preventDefault();
		if (params.id && formData && validate(formData, "item")) {
			formData.itemType = sanitizeValue(formData.itemType);
			formData.brandCategory = sanitizeValue(formData.brandCategory);
			formData.brand = sanitizeValue(formData.brand);
			formData.uom = sanitizeValue(formData.uom);
			formData.classification = sanitizeValue(formData.classification);
			formData.size = sanitizeValue(formData.size);
			formData.currency = sanitizeValue(formData.currency);
			formData.purchasePrice = sanitizeValue(formData.purchasePrice);
			formData.suggestedRetailPrice = sanitizeValue(formData.suggestedRetailPrice);
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateItem(sanitizeValue(params.id), formData, () => {
				refetchItems(dispatch, updateParams);
				navigate(-1);
			});
		}
		else {
			showNotification("error", "Missing Required Field");
		}
	};

	const handleRemoveItem = async (index: number, itemId: number) => {
		const data: ItemPayload = {...formData};
		let items: BundleItemPayload[] = [...data?.items ?? []];
		if (itemId === 0) {
			items.splice(index, 1);
			data.items = items;
			setFormData(data);
			return;
		} else {
			try {
				const response = await deleteBundleItem(sanitizeValue(params.id), itemId);
				if (response?.success) {
					items = items.filter(elem => elem.itemId !== itemId);
					data.items = items;
					setFormData(data);
				}
			} catch (err) {
				console.error(err);
				showNotification("error", "Unable to remove item");
			}
		}
	};

	return (
   		<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edits Item</span>
			</div>
        	<ItemForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				itemTypes={itemTypes}
				brandCategories={brandCategories}
				productCategories={productCategories}
				brands={brands}
				uoms={uoms}
				classifications={classifications}
				sizes={sizes}
				currencies={currencies}
				items={items}
				handleItemChange={handleItemChange}
				handleAddItem={handleAddItem}
				handleRemoveItemAsync={handleRemoveItem}
				isEdit={true}
			/>	
    	</main>
  	);
};
