import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrokerModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<BrokerModel> = {
	list: null,
	page: 0,
	count: 0,
};

const brokerSlice = createSlice({
	name: "brokers", initialState,
	reducers: {
		updateBrokers: (state, action: PayloadAction<SlicePayload<BrokerModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateBrokers } = brokerSlice.actions;
export default brokerSlice.reducer;
