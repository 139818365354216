import { getInbounds, addInbound } from "../../lib/api";
import { updateInbounds } from "../features/inboundSlice";
import type { AppDispatch } from "../store";
import { InboundModel } from "../../types/models";
import { InboundPayload } from "../../types/payloads";
import { getApi, mutationApi } from "./apiHooks";
import { QueryParams } from "../../types/common";

export function useGetInbounds() {
	return async function (dispatch: AppDispatch, params: QueryParams) {
		const page: number = (params?.offset ?? 0 / params?.limit ?? 10) + 1;
		const result = await getApi<InboundModel>(params, getInbounds);
		dispatch(updateInbounds({ list: result.list, count: result.count, page }));
	}
}

export function useAddInbound() {
	return async function (body: InboundPayload, cb: () => void) {
		if (await mutationApi(addInbound, body, "Inbound Added", "Unable to Add Inbound")){
			cb();
		}
	}
}
