import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShipmentPayload } from '../../types/payloads';
import { showNotification, sanitizeValue, formatDateInsert } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getBrands, getBrokers, getCompanies, getCurrencies, getItems, getShipment, getShipmentTypes, getUoms, getVendors, getWarehouses, } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { updateForm, fetchOne, fetchList } from "../../lib/handlers";
import ShipmentForm from './ShipmentForm';
import { useEditShipment, useGetShipments } from '../../redux/hooks/shipmentHooks';
import { BrandModel, BrokerModel, CompanyModel, CurrencyModel, ItemModel, ShipmentTypeModel, UomModel, VendorModel, WarehouseModel } from '../../types/models';

const queryParams = { offset: 0, limit: 1000 };
export default function EditShipment() {
	const [formData, setFormData] = useState<ShipmentPayload>({
		shipmentType: 0,
		shipmentNumber: "",
		sapPONumber: "",
		manualPO: "",
		loadingDate: "",
		broker: 0,
		vendor: 0,
		company: 0,
		brand: 0,
		item: 0,
		uom: 0,
		quantity: 0,
		pickupType: "",
		warehouse: 0,
		vesselETD: "",
		readyForBookingDate: "",
		sapPOCreationDate: "",
		sapPOApprovalDate: "",
		sapGrpo: "",
		documentsSubmissionDate: "",
		currency: 0,
		sapPOValue: 0,
		importationCost: 0,
  	});
	const [ brokers, setBrokers ] = useState<BrokerModel[]>([]);
	const [ vendors, setVendors ] = useState<VendorModel[]>([]);
	const [ items, setItems ] = useState<ItemModel[]>([]);
	const [ brands, setBrands ] = useState<BrandModel[]>([]);
	const [ uoms, setUoms ] = useState<UomModel[]>([]);
	const [ currencies, setCurrencies ] = useState<CurrencyModel[]>([]);
	const [ shipmentTypes, setShipmentTypes ] = useState<ShipmentTypeModel[]>([]);
	const [ companies, setCompanies ] = useState<CompanyModel[]>([]);
	const [ warehouses, setWarehouses ] = useState<WarehouseModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateShipment = useEditShipment();
	const getShipments = useGetShipments();
	const info = useAppSelector((state) => state.shipments);
	const params = useParams();

	useEffect(() => {
		if (!params.id || formData.shipmentType) return;

		fetchOne(sanitizeValue(params.id), getShipment, setFormData);
		fetchList(
			[
				getBrands, getUoms, getVendors, getBrokers ,
				getCurrencies, getItems, getCompanies, getShipmentTypes,
				getWarehouses,
			],
			queryParams,
			[
				setBrands, setUoms, setVendors, setBrokers,
				setCurrencies, setItems, setCompanies, setShipmentTypes,
				setWarehouses,
			]
		);
		
	}, [params]);

	const handleSubmit = (e: any) => {
        e.preventDefault();
		if (params.id && formData && validate(formData, "shipment")) {
			formData.quantity = sanitizeValue(formData.quantity);
			formData.sapPOValue = sanitizeValue(formData.sapPOValue);
			formData.importationCost = sanitizeValue(formData.importationCost);
			formData.loadingDate = formatDateInsert(formData.loadingDate);
			formData.vesselETD = formatDateInsert(formData.vesselETD);
			formData.readyForBookingDate = formatDateInsert(formData.readyForBookingDate);
			formData.sapPOCreationDate = formatDateInsert(formData.sapPOCreationDate);
			formData.sapPOApprovalDate = formatDateInsert(formData.sapPOApprovalDate);
			formData.documentsSubmissionDate = formatDateInsert(formData.documentsSubmissionDate);
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateShipment(sanitizeValue(params.id), formData, () => {
				getShipments(dispatch, updateParams);
				navigate(-1);
			});
		}
		else {
			showNotification("error", "Missing Required Field");
		}
	};


	const handleItemChange = (e: any) => {
		const selectedItem = items.find(item => item.id === Number(e.target.value));
		if (selectedItem) {
			setFormData(prev => ({
				...prev,
				item: selectedItem.id,
				brand: selectedItem.brand,
				uom: selectedItem.uom,
			}));
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Shipment</span>
			</div>
			<ShipmentForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				handleItemChange= {handleItemChange}
				formData={formData}
				shipmentTypes={shipmentTypes}
				brokers={brokers}
				vendors={vendors}
				companies={companies}
				brands={brands}
				items={items}
				uoms={uoms}
				warehouses={warehouses}
				currencies={currencies}
			/>
    	</main>
  	);
};
