import { getProductCategories, updateProductCategory, deleteProductCategory, addProductCategory } from "../../lib/api";
import { updateProductCategories } from "../features/productCategorySlice";
import type { AppDispatch } from "../store";
import { ProductCategoryModel } from "../../types/models";
import { ProductCategoryPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetProductCategories() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ProductCategoryModel>(params, getProductCategories);
		dispatch(updateProductCategories({ list: result.list, count: result.count, page }));
	}
}

export function useAddProductCategory() {
	return async function (body: ProductCategoryPayload, cb: () => void) {
		if (await mutationApi(addProductCategory, body, "Product Category Added", "Unable to Add Product Category")){
			cb();
		}
	}
}

export function useEditProductCategory() {
	return async function (id: number, body: ProductCategoryPayload, cb: () => void) {
		if (await mutationApi(updateProductCategory, { id, body }, "Product Category Updated", "Unable to Update Product Category")){
			cb();
		}
	}
}

export function useDeleteProductCategory() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteProductCategory, "Product Category")) {
			cb();
		}
	}
}
