import { getClassifications, updateClassification, deleteClassification, addClassification } from "../../lib/api";
import { updateClassifications } from "../features/classificationSlice";
import type { AppDispatch } from "../store";
import { ClassificationModel } from "../../types/models";
import { ClassificationPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetClassifications() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<ClassificationModel>(params, getClassifications);
		dispatch(updateClassifications({ list: result.list, count: result.count, page }));
	}
}

export function useAddClassification() {
	return async function (body: ClassificationPayload, cb: () => void) {
		if (await mutationApi(addClassification, body, "Classification Added", "Unable to Add Classification")){
			cb();
		}
	}
}

export function useEditClassification() {
	return async function (id: number, body: ClassificationPayload, cb: () => void) {
		if (await mutationApi(updateClassification, { id, body }, "Classification Updated", "Unable to Update Classification")){
			cb();
		}
	}
}

export function useDeleteClassification() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteClassification, "Classification")) {
			cb();
		}
	}
}
