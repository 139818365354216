import {
	Dialog, DialogTitle, DialogContent, DialogContentText,
	DialogActions,
} from '@mui/material';
import { styles } from "../../lib/styles";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	theme: string;
	title: string;
	text: string;
};

export default function DeleteDialog(props: Props) {
	return (
		<Dialog
			open={props.isOpen}
			onClose={props.onClose}
			aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { backgroundColor: props.theme === 'dark' ? '#333533' : 'white' },
                }}	
		>
			<DialogTitle
				id="alert-dialog-title"
				style={{
					fontFamily: "Poppins, sans-serif",
					color: "red",
					fontSize: "20px",
					textAlign: "center",
					backgroundColor: "#202829",
				}}
			>
				{props.title}
			</DialogTitle>
			<DialogContent
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: 8
				}}
			>
				<DialogContentText
					id="alert-dialog-description"
					style={{
						fontFamily: 'Poppins, sans-serif',
						color: props.theme === 'dark' ? 'white' : 'black',
						fontSize: '20px',
						textAlign: 'center',
					}}
				>
					{props.text}
				</DialogContentText>
			</DialogContent>
			<div className='flex justify-center items-center'>
				<DialogActions>
					<button onClick={props.onConfirm} className={styles.dialogBtnContainer}>
						<span className={styles.dialogBtnSuccessOverlay}></span>
						<span className={styles.dialogBtnSuccessTranslate}></span>
						<span className={styles.dialogBtnSuccessText}>Yes</span>
						<span className={styles.dialogBtnSuccessBorder}></span>
					</button>
					<button onClick={props.onClose} className={styles.dialogBtnContainer}>
						<span className={styles.dialogBtnDangerOverlay}></span>
						<span className={styles.dialogBtnDangerTranslate}></span>
						<span className={styles.dialogBtnDangerText}>No</span>
						<span className={styles.dialogBtnDangerBorder}></span>
					</button>
				</DialogActions>
			</div>
		</Dialog>
	);
}
