import { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { OutboundPayload } from '../../lib/payload';
import { queryConfigs } from '../../react-query/queryConfig';
import { Customer, Item, Warehouse } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helpers';
import { useMutationQuery } from '../../react-query/queryHook';
import { getCustomers, getItems, getWarehouses } from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function AddOutbound() {
  const { mutationFn, queryKey } = queryConfigs.addOutbound
  const [formData, setFormData] = useState<OutboundPayload>({
    warehouse: 0,
    item: 0,
    poNumber: "",
    poQuantity: 0,
    uom: 0,
    customer: 0,
  });
  const [ items, setItems ] = useState<Item[]>([]);
  const [ warehouses, setWarehouses ] = useState<Warehouse[]>([]);
  const [ customers, setCustomers ] = useState<Customer[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const onSuccess = () => {
          showNotification("success", "Outbound Added");
          navigate(-1);
      };

  const addMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

  useEffect(() => {
      fetchItems();
      fetchWarehouses();
      fetchCustomers();
  }, []);

  const fetchItems = async () => {
      try {
          const response = await getItems({offset:0 , limit: 1000});
          if (response?.success) {
              setItems (response?.result?.list as Item [] ?? []);
          }
      } catch (err) {
          console.error(err);
      }
  };

  const fetchWarehouses = async () => {
      try {
          const response = await getWarehouses({offset:0 , limit: 1000});
          if (response?.success) {
              setWarehouses (response?.result?.list as Warehouse [] ?? []);
          }
      } catch (err) {
          console.error(err);
      }
  };

  const fetchCustomers = async () => {
      try {
          const response = await getCustomers({offset:0 , limit: 1000});
          if (response?.success) {
              setCustomers (response?.result?.list as Customer [] ?? []);
          }
      } catch (err) {
          console.error(err);
      }
  };

  const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setFormData({
      ...formData,
      [name]: value,
      });
  };

  const handleSubmit = (e: any) => {
      e.preventDefault();
      if (validate(formData, "outbound")) {
        formData.warehouse = sanitizeValue(formData.warehouse);
        formData.item = sanitizeValue(formData.item);
        formData.poQuantity = sanitizeValue(formData.poQuantity);
        formData.uom = items?.filter(item => item.id === sanitizeValue(formData.item))[0]?.uom;
        formData.customer = sanitizeValue(formData.customer);
        addMutation.mutate(formData);
      }
      else {
          showNotification("error", "Missing Required Field");
      }
  };

  const handleSelectChange = (e: any) => {
      const { name, value } = e.target;
      setFormData({
      ...formData,
      [name as string]: value as string,
      });
  };

const handleCancel = () => {
  navigate(-1);
};

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Add Outbound
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Warehouse <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="warehouse"
                value={formData.warehouse}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {warehouses?.map((item: Warehouse) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Item Code <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="item"
                value={formData.item}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                    {items?.map((item: Item) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.itemCode}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Parent Code <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="item"
                value={formData.item}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {items?.map((item: Item) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.parentCode}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Item Name <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="item"
                value={formData.item}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {items?.map((item: Item) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.itemName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">UOM Group <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="item"
                value={formData.item}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {items?.map((item: Item) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.uomGroup}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">PO Quantity <span className='text-red-400'>*</span></h2>
            <TextField
                name="poQuantity"
                placeholder="Enter PO Quantity"
                variant="outlined"
                value={formData.poQuantity === 0 ? '' : formData.poQuantity}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Customer Name <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="customer"
                value={formData.customer}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {customers?.map((item: Customer) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">PO Number <span className='text-red-400'>*</span></h2>
            <TextField
                name="poNumber"
                placeholder="Enter PO Number"
                variant="outlined"
                value={formData?.poNumber}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
                <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#7c5082] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#7c5082] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#7c5082] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                    <span className="absolute inset-0 border-2 border-[#7c5082] rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-gray-500 opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-gray-500 opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-gray-500 transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-gray-500 rounded-lg"></span>
                </button>
            </div>
    </main>
  );
};
