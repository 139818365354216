import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoleModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<RoleModel> = {
	list: null,
	page: 0,
	count: 0,
};

const roleSlice = createSlice({
	name: "roles", initialState,
	reducers: {
		updateRoles: (state, action: PayloadAction<SlicePayload<RoleModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateRoles } = roleSlice.actions;
export default roleSlice.reducer;
