import { TextField, MenuItem, Select } from '@mui/material';
import { styles, getThemeSelectStyle } from "../../lib/styles";
import { VendorPayload } from "../../types/payloads";
import FormFooter from "../common/FormFooter";
import { CountryModel } from "@/types/models";
import { useTheme } from "@mui/material/styles";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: VendorPayload;
	countries: CountryModel[];
};

export default function VendorForm(props: Props) {
	const theme = useTheme();
	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Vendor Code<span className="text-red-400">*</span></h2>
					<TextField
						name="code"
						placeholder="Vendor Code"
						variant="outlined"
						value={props.formData?.code}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Vendor Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Vendor Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
				<div>
					<h2 className={styles.labelStyle}>Country of Origin<span className="text-red-400">*</span></h2>
					<Select
						name="country"
						value={`${props.formData?.country}`}
						onChange={props.updateForm}
						displayEmpty
						inputProps={{className: 'dark:text-white text-black',}}
						className='bg-slate-100 dark:bg-[#202829]'
						sx={getThemeSelectStyle(theme)}
					>
						{props.countries?.map((item: CountryModel) => (
							<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
