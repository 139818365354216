import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TextField,
	Box, Modal,
} from '@mui/material';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { sanitizeValue } from '../../lib/helpers';
import { OutboundModel, CustomerModel, WarehouseModel, LoginResult } from '../../types/models';
import { getCustomers, getWarehouses, getExportURL } from '../../lib/api';
import Import from "../Import";
import { useGetOutbounds } from "../../redux/hooks/outboundHooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { TableItem, QueryParams } from "../../types/common";
import TableRowItem from "../common/TableRowItem";
import { fetchList } from "../../lib/handlers";
import { getThemeSelectStyle, getBoxStyles } from "../../lib/styles";
import { useTheme } from "@mui/material/styles";

const headers: string[] = [
    "Date", "Warehouse", "Item Code", "Parent Code",
    "Item Name", "Uom Group", "PO Quantity",
    "Quantity in Pieces", "Customer Name",
    "PO Number",
];
const limit = 10;
const params: QueryParams = { offset: 0, limit: 1000 };
export default function OutBounds ({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const info = useAppSelector((state) => state.outbounds);
	const [currentPage, setCurrentPage] = useState(info?.page ?? 1);
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemName");
	const [searchForm, setSearchForm] = useState<QueryParams>({
		offset: 0, limit: 10,
        itemName: "", itemCode: "", warehouse: "",
        customer: "", startDate: "", endDate: "",
        poNumber: "",
    });
    const navigate = useNavigate();
    const [warehouses, setWarehouses] = useState<WarehouseModel[]>([])
    const [customers, setCustomers] = useState<CustomerModel[]>([])
	const [openImport, setOpenImport] = useState(false);
	const [tableData, setTableData] = useState<TableItem[][]>([]);
    const theme = useTheme();
	const getOutbounds = useGetOutbounds();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!info.list) {
			getData(searchForm);
		} else if (info?.list?.length){
			prepareData(info.list);
		}
	}, [info]);

	useEffect(() => {
		fetchList(
			[getWarehouses, getCustomers],
			{ ...params, limit: 1000 },
			[setWarehouses, setCustomers],
		);
	}, []);

	const getData = (params: QueryParams) => getOutbounds(dispatch, params);
	const prepareData = (items: OutboundModel[]) => {
		const data: TableItem[][] = [];
		let counter = 0;
		for (const item of items) {
			data.push([
				{ key: `${counter}-1`, value: item?.createdOn ?? "", valueType: "date" },
				{ key: `${counter}-2`, value: item?.warehouseCode ?? "", valueType: "text" },
				{ key: `${counter}-3`, value: item?.itemCode ?? "", valueType: "text" },
				{ key: `${counter}-4`, value: item?.parentCode ?? "", valueType: "text" },
				{ key: `${counter}-5`, value: item?.itemName ?? "", valueType: "text" },
				{ key: `${counter}-6`, value: item?.uomGroup ?? "", valueType: "text" },
				{ key: `${counter}-7`, value: item?.poQuantity ?? "", valueType: "text" },
				{ key: `${counter}-8`, value: item?.pieces ?? "", valueType: "text" },
				{ key: `${counter}-9`, value: item?.customerName ?? "", valueType: "text" },
				{ key: `${counter}-10`, value: item?.poNumber ?? "", valueType: "text" },
			]);
			counter += 1;
		}
		setTableData(data);
	};

    const handleAddClick = () => {
        navigate('add');
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
		const paramValues = {
			...searchForm,
			itemName: searchType === "itemName" ? searchValue: "",
            itemCode: searchType === "itemCode" ? searchValue: "",
            warehouse: searchType === "warehouse" ? searchValue: "",
            customer: searchType === "customer" ? searchValue: "",
            startDate: searchType === "startDate" ? searchValue: "",
            endDate: searchType === "endDate" ? searchValue: "",
            poNumber: searchType === "poNumber" ? searchValue: "",
		};
		setSearchForm(paramValues);
		getData({ ...paramValues, ...params });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemName");
		setSearchForm(prev => ({
			...searchForm,
			itemName: "", itemCode: "", warehouse: "",
			customer: "", poNumber: "", startDate: "",
			endDate: "",
		}));
		getData(params);
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    }

	const handleReload = () => {
		handleSearchClear();
	};

	const exportHandler = () => {
		let url: string = getExportURL("outbounds");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&itemName=${searchForm?.itemName ?? ""}&
			parentCode=${searchForm?.itemCode ?? ""}&warehouse=${searchForm?.warehouse ?? ""}&
			customer=${searchForm?.customer ?? ""}&startDate=${searchForm?.startDate ?? ""}&
			endDate=${searchForm?.endDate ?? ""}&poNumber=${searchForm?.poNumber ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={headers}
                totalRows={sanitizeValue(info.count)}
                colSpan={headers.length}
                pageTitle={pageTitle}
                marginTop={8}
                componentType='stockOut'
                onAddClick={handleAddClick}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    	className: 'dark:text-white text-black',
                                    }}
                                    sx={getThemeSelectStyle(theme)}
                                	className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="itemName" style={{ fontFamily: 'Poppins' }}>Item Name</MenuItem>
                                    <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                    <MenuItem value="poNumber" style={{ fontFamily: 'Poppins' }}>Po Number</MenuItem>
                                    <MenuItem value="warehouse" style={{ fontFamily: 'Poppins' }}>Warehouse</MenuItem>
                                    <MenuItem value="customer" style={{ fontFamily: 'Poppins' }}>Customer</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["itemName", "itemCode", "poNumber"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                        }}
                                        sx={getThemeSelectStyle(theme)}
                                        className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"warehouse".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Warehouse</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                            }}
                                            sx={getThemeSelectStyle(theme)}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {warehouses?.map((item: WarehouseModel) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {"customer".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Customer</h2>
                                    <FormControl sx={{ width: "50%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                            }}
                                            sx={getThemeSelectStyle(theme)}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {customers?.map((item: CustomerModel) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
            	{tableData.map((items: TableItem[]) => (
					<TableRowItem key={items[0].key} items={items} />
				))}    
            </CommonTable>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={getBoxStyles(theme.palette.mode)} >
					<Import fileType="outbounds" refresh={() => getData(params)} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
