import { TableCell, TableRow as MUITableRow, Tooltip, Chip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from 'react-icons/md';
import { styles } from "../../lib/styles";
import { TableItem, TableAction } from "../../types/common";
import { sanitizeValue } from "../../lib/helpers";
import { PiEyeFill } from 'react-icons/pi';

type Props = {
	items: TableItem[];
};

function renderAction(action: TableAction, id: number) {
	if (action.actionType === "view") {
		return (
			<Tooltip title="View" arrow>
				<button className="edit-btn" onClick={() => action.handler(id)}>
					<PiEyeFill />
				</button>
			</Tooltip>
		);
	}
	if (action.actionType === "edit") {
		return (
			<Tooltip title="Edit" arrow>
				<button className="edit-btn" onClick={() => action.handler(id)}>
					<FaEdit />
				</button>
			</Tooltip>
		);
	}
	if (action.actionType === "delete") {
		return (
			<Tooltip title="Delete" arrow>
				<button className="del-btn" onClick={() => action.handler(id)}>
					<MdDeleteForever />
				</button>
			</Tooltip>
		);
	}
}

function renderRow(item: TableItem) {
	switch (item.valueType) {
		case "chip":
			return (
				<Chip
                    label={item?.chipLabel ?? ""}
                    sx={item?.chipStyle ?? { backgroundColor: "#607d8b", color: "#000" }}
                    size="small"
                />
			);
		case "action":
			return (
				<div className={styles.toolTipContainerStyle}>
					{item?.actions?.map((action: TableAction, index: number) => (
						<div key={index}>{renderAction(action, sanitizeValue(item.id))}</div>
					))}
				</div>
			);
		case "date":
			return new Date(item.value).toDateString();
		case "image":
			return item.value ? <img
				src={`${item.value}`}
				alt={`${item.value}`}
				style={{
					width: '50px', height: '50px',
					objectFit: 'cover', borderRadius: '1rem',
				}}
			/> : "No Image";
		default:
			return item.value;
	}
	
}

export default function TableRowItem(props: Props) {
	return (
		<MUITableRow style={{ height: "60px" }} className="bg-white dark:bg-[#101422]">
			{props?.items?.map((item: TableItem) => (
				<TableCell sx={{ fontSize: "13px" }} key={item.key} align="center" className={styles.tableCellClass}>
					{renderRow(item)}
				</TableCell>
			))}
		</MUITableRow>
	);
}
