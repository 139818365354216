import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClassificationModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<ClassificationModel> = {
	list: null,
	page: 0,
	count: 0,
};

const classificationSlice = createSlice({
	name: "classifications", initialState,
	reducers: {
		updateClassifications: (state, action: PayloadAction<SlicePayload<ClassificationModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateClassifications } = classificationSlice.actions;
export default classificationSlice.reducer;
