import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UomModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<UomModel> = {
	list: null,
	page: 0,
	count: 0,
};

const uomSlice = createSlice({
	name: "uoms", initialState,
	reducers: {
		updateUoms: (state, action: PayloadAction<SlicePayload<UomModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateUoms } = uomSlice.actions;
export default uomSlice.reducer;
