const dialogBtnOverlay = `
	w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0
	top-0 opacity-[3%]
`;
const dialogBtnTranslate = `
	absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500
	ease-in-out rotate-45 -translate-x-56 -translate-y-24
	opacity-100 group-hover:-translate-x-8
`;
const dialogBtnText = `
	relative w-full text-left transition-colors duration-200
	text-sm ease-in-out group-hover:text-white
`;
const dialogBtnBorder = "absolute inset-0 border-2 rounded-lg";
const formBtnTranslate = `
	w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 opacity-[3%]
`;
const formBtnTransition = `
	absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out
	rotate-45 -translate-x-56 -translate-y-24 opacity-100 group-hover:-translate-x-8
`;
const formBtnText = `
	relative w-full text-left transition-colors duration-200
	ease-in-out group-hover:text-white font-normal text-sm font-Poppins	
`;
const formBorder = "absolute inset-0 border-2 rounded-lg";

export const styles = {
	mainContainer: "mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md",
	imageSection: "flex flex-col justify-center items-center w-full",
	tableCellStyle: {
		fontFamily: 'Poppins,sans-serif',
		fontWeight: 500,
		fontSize: '12px',
	},
	tableCellClass: "capitalize text-gray-800 dark:text-white",
	toolTipContainerStyle: "flex justify-center items-center space-x-2",
	dialogBtnContainer: `
		relative items-center justify-start inline-block px-5 py-2 overflow-hidden
		font-bold rounded-lg group
	`,
	dialogBtnSuccessOverlay: dialogBtnOverlay + " bg-[#7c5082]",
	dialogBtnDangerOverlay: dialogBtnOverlay + " bg-gray-500",
	dialogBtnSuccessTranslate: dialogBtnTranslate + " bg-[#7c5082]",
	dialogBtnDangerTranslate: dialogBtnTranslate + " bg-gray-500",
	dialogBtnSuccessText: dialogBtnText + " text-[#7c5082]",
	dialogBtnDangerText: dialogBtnText + " text-gray-500",
	dialogBtnSuccessBorder: dialogBtnBorder + " border-[#7c5082]",
	dialogBtnDangerBorder: dialogBtnBorder + " border-gray-500",
	inputStyle: {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	titleContainer: "text-left text-2xl font-Poppins font-semibold ml-5",
	formStyle: "grid grid-cols-2 gap-4 py-6 justify-center items-center",
	formBtnContainer: `
		relative items-center justify-start inline-block px-5 py-2 overflow-hidden
		font-bold rounded-lg group
	`,
	formBtnSuccessTranslate: formBtnTranslate + "bg-[#7c5082]",
	formBtnDangerTranslate: formBtnTranslate + "bg-gray-500",
	formBtnSuccessTransition: formBtnTransition + "bg-[#7c5082]",
	formBtnDangerTransition: formBtnTransition + "bg-gray-500",
	formBtnSuccessText: formBtnText + "text-[#7c5082]",
	formBtnDangerText: formBtnText + "text-gray-500",
	formBorderSuccess: formBorder + " border-[#7c5082]",
	formBorderDanger: formBorder + " border-gray-500",
	labelStyle: "text-sm font-Poppins font-base mb-2",
	labelSmall: "font-Poppins text-xs font-black",
	dpContainer: `
		w-40 h-40 rounded-full bg-gray-200 flex items-center justify-center
		cursor-pointer overflow-hidden relative group border-4 transition-all
		duration-300
	`,
	dpContent: `
		relative w-full h-full flex justify-center items-center group-hover:blur-sm
		transition-all duration-300
	`,
	profilePic: `
		absolute top-1/2 -translate-y-1/2 text-center opacity-0
		group-hover:opacity-100 transition-opacity duration-300
	`,
	uploadText: "text-black font-Poppins font-black",
	roundImage: "w-40 h-40 rounded-full border border-gray-400",
	roundImageSmall: "w-10 h-10 rounded-full border border-gray-400",
	orderCard: `
		flex-row gap-4 bg-[#edf1ea] border border-gray-200 dark:bg-[#202920]
		text-black dark:text-white p-4 flex justify-center items-start rounded-xl
		drop-shadow-lg cursor-pointer
	`,
	orderCardLabel: `text-sm text-teal-500 font-medium font-Poppins`,
	orderCardText: "text-sm font-black text-black dark:text-white",
	orderCardBtn: "relative mt-4 inline-block px-4 py-2 font-medium group",
	orderCardBtnTranslate: `
		absolute inset-0 w-full h-full transition duration-200 ease-out
		transform translate-x-1 translate-y-1 bg-[#1985a1] group-hover:-translate-x-0
		group-hover:-translate-y-0
	`,
	orderCardBtnBorder: `
		absolute inset-0 w-full h-full bg-white border-2
		border-[#1985a1] group-hover:bg-[#1985a1]
	`,
	orderCardBtnText: "relative text-[#1985a1] group-hover:text-white font-Poppins",
	addBtnStyle: `
		mt-3 mb-3 text-white rounded-lg drop-shadow-lg font-Poppins px-3
		bg-[#4CAF50] border border-[#4CAF50] hover:text-[#4CAF50] hover:bg-white
		dark:hover:text-[#4CAF50] dark:hover:bg-[#1f2937]
	`,
	orderCategoryBtn: "bg-white dark:bg-[#202829] rounded-full flex flex-col items-center",
	orderCategoryImg: `
		mb-2 rounded-full w-[200px] h-[130px] transform transition-transform
		duration-350 hover:rotate-[15deg]
	`,
	cartBtnLeft: "bg-[#353535] text-white px-2 py-1 rounded-l",
	cartBtnBlue: "bg-blue-500 text-white px-2 py-1 rounded-r",
	cartBtnRed: "bg-red-500 text-white px-2 py-2 rounded",
	lightText: "font-Poppins text-xs font-light",
	addOrderContainer: `
		mt-20 bg-white dark:bg-[#141729] p-6 gap-12 drop-shadow-lg
		grid grid-cols-3 mx-auto relative
	`,
	orderItemImg: "mb-2 rounded-full w-[100px] h-[80px]",
	orderItemText: "font-Poppins text-xs font-black mb-2",
	orderInputText: {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	autocompleteInputStyle: {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: 'auto',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	datepickerText: {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			color: 'inherit',
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiOutlinedInput-input': {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '13px',
			'&::placeholder': {
				fontFamily: 'Poppins, sans-serif',
				fontSize: '12px',
			},
		},
	},
};

export const getThemeSelectStyle = (theme: any) => ({
	width: '100%',
	'& .MuiOutlinedInput-root': {
		borderRadius: '0.5rem',
		height: '44px',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent',
	},
	'& .MuiSelect-select': {
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
	},
	'& .MuiSelect-icon': {
		color: theme.palette.text.primary,
	},
	'& .MuiOutlinedInput-input': {
		padding: '10px 14px',
	},
});

export const getCheckboxStyle = (theme: any) => ({
	'&.MuiCheckbox-root': {
		color: theme.palette.grey[600],
	},
	'&.Mui-checked': {
		color: '#7c5082',
	},
});

export const getThemeAutocompleteChipStyle = (theme: string) => ({
	color: theme === 'dark' ? 'white' : 'black',
	borderRadius: '0.375rem',
	height: 'auto',
	'& .MuiChip-deleteIcon': {
  		color: theme === 'dark' ? '#FFFFFF' : '#000000',
	},	
});

export const getBoxStyles = (theme: string) => ({
	position: 'absolute',
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: theme === 'dark' ? '#20293D' : 'white',
	color: theme === 'dark' ? 'white' : 'black',
	borderRadius: '10px',
	boxShadow: 24,
});
