import { getBrandCategories, updateBrandCategory, deleteBrandCategory, addBrandCategory } from "../../lib/api";
import { updateBrandCategories } from "../features/brandCategorySlice";
import type { AppDispatch } from "../store";
import { BrandCategoryModel } from "../../types/models";
import { BrandCategoryPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetBrandCategories() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<BrandCategoryModel>(params, getBrandCategories);
		dispatch(updateBrandCategories({ list: result.list, count: result.count, page }));
	}
}

export function useAddBrandCategory() {
	return async function (body: BrandCategoryPayload, cb: () => void) {
		if (await mutationApi(addBrandCategory, body, "Brand Category Added", "Unable to Add Brand Category")){
			cb();
		}
	}
}

export function useEditBrandCategory() {
	return async function (id: number, body: BrandCategoryPayload, cb: () => void) {
		if (await mutationApi(updateBrandCategory, { id, body }, "Brand Category Updated", "Unable to Update Brand Category")){
			cb();
		}
	}
}

export function useDeleteBrandCategory() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteBrandCategory, "Brand Category")) {
			cb();
		}
	}
}
