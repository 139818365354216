import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductCategoryPayload } from '../../types/payloads';
import { ItemTypeModel, BrandCategoryModel } from "../../types/models";
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getItemTypes, getProductCategory, getBrandCategories } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import ProductCategoryForm from "./ProductCategoryForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetProductCategories, useEditProductCategory } from "../../redux/hooks/productCategoryHooks";
import { updateForm, fetchOne, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function EditProductCategory() {
    const [formData, setFormData] = useState<ProductCategoryPayload>({
		name: "",
  	});
	const [itemTypes, setItemTypes] = useState<ItemTypeModel[]>([]);
	const [brandCategories, setBrandCategories] = useState<BrandCategoryModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateProductCategory = useEditProductCategory();
	const getProductCategories = useGetProductCategories();
	const info = useAppSelector((state) => state.productCategories);
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getProductCategory, setFormData);
			fetchList([getItemTypes, getBrandCategories], queryParams, [setItemTypes, setBrandCategories]);
		}
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<ProductCategoryPayload>(formData, "productCategory")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateProductCategory(sanitizeValue(params.id), formData, () => {
				getProductCategories(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Product Category</span>
			</div>
			<ProductCategoryForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				itemTypes={itemTypes}
				brandCategories={brandCategories}
			/>
    	</main>
  	);
};
