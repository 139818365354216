import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserPayload } from '../../types/payloads';
import { RoleModel, MetadataModel } from "../../types/models";
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getRoles, getUser, getMetadata } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import UserForm from "./UserForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetUsers, useEditUser } from "../../redux/hooks/userHooks";
import { updateForm, fetchOne, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function EditUser() {
    const [formData, setFormData] = useState<UserPayload>({
		name: "", email: "", userId: "", permissions: [],
  	});
	const [roles, setRoles] = useState<RoleModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateUser = useEditUser();
	const getUsers = useGetUsers();
	const info = useAppSelector((state) => state.users);
	const params = useParams();

	useEffect(() => {
		if(!params.id || formData.name) return;
		fetchOne(sanitizeValue(params.id), getUser, (data: UserPayload) => {
			const modules = new Set<number>();
			for (const item of data?.permissions) {
				modules.add(item.module);
			}
			const list = [...data.permissions];
			fetchOne(0, getMetadata, (result: MetadataModel) => {
				for (const item of result?.modules) {
					if (!modules.has(item.id)) {
						list.push({
							module: item.id,
							moduleName: item.name,
							hasAdd: 0, hasEdit: 0,
							hasView: 0, hasDelete: 0,
						});
					}
				}
				data.permissions = list;
				setFormData(data);	
			});
		});
		fetchList([getRoles], queryParams, [setRoles]);
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<UserPayload>(formData, "user")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateUser(sanitizeValue(params.id), formData, () => {
				getUsers(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit User</span>
			</div>
			<UserForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				setFormData={setFormData}
				roles={roles}
			/>
    	</main>
  	);
};
