import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { useTheme } from '../../ThemeContext';
import { styles } from "../../lib/styles";
import ShipmentTypeForm from "./ShipmentTypeForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetShipmentTypes, useAddShipmentType } from "../../redux/hooks/shipmentTypeHooks";
import { updateForm } from "../../lib/handlers";
import { ShipmentTypePayload } from "../../types/payloads";

export default function AddShipmentType() {
	const [formData, setFormData] = useState<ShipmentTypePayload>({ name: "" });
  	const navigate = useNavigate();
  	const { theme } = useTheme();
	const dispatch = useAppDispatch();
	const addShipmentType = useAddShipmentType();
	const getShipmentTypes = useGetShipmentTypes();
	const info = useAppSelector((state) => state.shipmentTypes);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<ShipmentTypePayload>(formData, "shipmentType")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addShipmentType(formData, () => {
				getShipmentTypes(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Shipment Type</span>
			</div>
			<ShipmentTypeForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				theme={theme}
			/>
    	</main>
  	);
}
