import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WarehouseModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<WarehouseModel> = {
	list: null,
	page: 0,
	count: 0,
};

const warehouseSlice = createSlice({
	name: "warehouses", initialState,
	reducers: {
		updateWarehouses: (state, action: PayloadAction<SlicePayload<WarehouseModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateWarehouses } = warehouseSlice.actions;
export default warehouseSlice.reducer;
