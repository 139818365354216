import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<ChannelModel> = {
	list: null,
	page: 0,
	count: 0,
};

const channelSlice = createSlice({
	name: "channels", initialState,
	reducers: {
		updateChannels: (state, action: PayloadAction<SlicePayload<ChannelModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateChannels } = channelSlice.actions;
export default channelSlice.reducer;
