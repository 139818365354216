import {
	TextField, TableContainer, Paper, Table, TableHead, TableRow,
	TableCell, TableBody,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styles, getCheckboxStyle } from "../../lib/styles";
import { RolePayload } from "../../types/payloads";
import { RolePermissionModel } from "../../types/models";
import { useTheme } from "@mui/material/styles";
import FormFooter from "../common/FormFooter";

type Props = {
	submitHandler: (e: any) => void;
	onCancel: () => void;
	updateForm: (e: any) => void;
	formData: RolePayload;
	setFormData: (e: any) => void;
};

const headerStyle = {fontFamily:'Poppins', fontSize:'12px', color: 'white'}
export default function RoleForm(props: Props) {
	const theme = useTheme();
	const checkboxStyle = getCheckboxStyle(theme);

	const handleCheckboxChange = (e: any, index: number, permissionKey: string) => {
		const form = {...props.formData};
		const permissionList = [...form.permissions];
		let permission = permissionList[index];
		permission = { ...permission, [permissionKey]: e.target.checked ? 1 : 0 };
		permissionList[index] = permission;
		form.permissions = permissionList;
		props.setFormData(form);
	};

	return (
		<form onSubmit={props.submitHandler}>
			<div className={styles.formStyle}>
				<div>
					<h2 className={styles.labelStyle}>Name<span className="text-red-400">*</span></h2>
					<TextField
						name="name"
						placeholder="Name"
						variant="outlined"
						value={props.formData?.name}
						onChange={props.updateForm}
						inputProps={{ className: 'dark:text-white text-black' }}
						sx={styles.inputStyle}
						className='bg-slate-100 dark:bg-[#202829] rounded-lg'
					/>
				</div>
			</div>
			{/* Permissions Table */}
			<div className="py-4">
				<span className="font-Poppins font-semibold text-base">Permissions</span>
				<TableContainer component={Paper} className="mt-2">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={headerStyle} className="bg-[#7c5082]">Modules</TableCell>
								<TableCell  style={headerStyle} className="bg-[#7c5082]" align="center">Add</TableCell>
								<TableCell  style={headerStyle} className="bg-[#7c5082]" align="center">Edit</TableCell>
								<TableCell  style={headerStyle} className="bg-[#7c5082]" align="center">View</TableCell>
								<TableCell  style={headerStyle} className="bg-[#7c5082]" align="center">Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.formData.permissions?.map((item: RolePermissionModel, index: number) => (
								<TableRow key={item.module} className='bg-white dark:bg-[#14172a]'>
									<TableCell className={styles.tableCellClass}>{item.moduleName}</TableCell>
									<TableCell align="center">
										<Checkbox
											checked={item.hasAdd === 1}
											onChange={(e: any) => handleCheckboxChange(e, index, 'hasAdd')}
											sx={checkboxStyle}
										/>
									</TableCell>
									<TableCell align="center">
										<Checkbox
											checked={item.hasEdit === 1}
											onChange={(e: any) => handleCheckboxChange(e, index, 'hasEdit')}
											sx={checkboxStyle}
										/>
									</TableCell>
									<TableCell align="center">
										<Checkbox
											checked={item.hasView === 1}
											onChange={(e: any) => handleCheckboxChange(e, index, 'hasView')}
											sx={checkboxStyle}
										/>
									</TableCell>
									<TableCell align="center">
										<Checkbox
											checked={item.hasDelete === 1}
											onChange={(e: any) => handleCheckboxChange(e, index, 'hasDelete')}
											sx={checkboxStyle}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<FormFooter onCancel={props.onCancel} />
		</form>
	);
}
