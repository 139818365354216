import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SalesCategoryPayload } from '../../types/payloads';
import { showNotification } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import SalesCategoryForm from "./SalesCategoryForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetSalesCategories, useAddSalesCategory } from "../../redux/hooks/salesCategoryHooks";
import { updateForm } from "../../lib/handlers";

export default function AddSalesCategory() {
    const [formData, setFormData] = useState<SalesCategoryPayload>({
		name: "",
  	});
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addSalesCategory = useAddSalesCategory();
	const getSalesCategories = useGetSalesCategories();
	const info = useAppSelector((state) => state.salesCategories);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<SalesCategoryPayload>(formData, "salesCategory")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addSalesCategory(formData, () => {
				getSalesCategories(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Sales Category</span>
			</div>
			<SalesCategoryForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
			/>
    	</main>
  	);
};
