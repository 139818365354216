import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandPayload } from '../../types/payloads';
import { CompanyModel } from "../../types/models";
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getCompanies } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import BrandForm from "./BrandForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetBrands, useAddBrand } from "../../redux/hooks/brandHooks";
import { updateForm, fetchList } from "../../lib/handlers";

const queryParams = { offset: 0, limit: 1000 };
export default function AddBrand() {
    const [formData, setFormData] = useState<BrandPayload>({
		name: "", code: "",
  	});
	const [companies, setCompanies] = useState<CompanyModel[]>([]);
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addBrand = useAddBrand();
	const getBrands = useGetBrands();
	const info = useAppSelector((state) => state.brands);

	useEffect(() => {
		fetchList([getCompanies], queryParams, [setCompanies]);
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<BrandPayload>(formData, "brand")) {
			formData.landedCostFactor = sanitizeValue(formData.landedCostFactor);
			formData.retailerMargin = sanitizeValue(formData.retailerMargin);
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			addBrand(formData, () => {
				getBrands(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Add Brand</span>
			</div>
			<BrandForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
				companies={companies}
			/>
    	</main>
  	);
};
