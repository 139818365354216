import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SizeModel } from "../../types/models";
import { SlicePayload } from "../../types/common";

const initialState: SlicePayload<SizeModel> = {
	list: null,
	page: 0,
	count: 0,
};

const sizeSlice = createSlice({
	name: "sizes", initialState,
	reducers: {
		updateSizes: (state, action: PayloadAction<SlicePayload<SizeModel>>) => {
			state.list = action.payload.list;
			state.page = action.payload.page;
			state.count = action.payload.count;
		}
	}
})

export const { updateSizes } = sizeSlice.actions;
export default sizeSlice.reducer;
