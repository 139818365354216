import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SalesCategoryPayload } from '../../types/payloads';
import { showNotification, sanitizeValue } from '../../lib/helpers';
import { validate } from '../../lib/validation';
import { getSalesCategory } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";
import { styles } from "../../lib/styles";
import SalesCategoryForm from "./SalesCategoryForm";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/mainHook";
import { Params } from "../../redux/hooks/apiHooks";
import { useGetSalesCategories, useEditSalesCategory } from "../../redux/hooks/salesCategoryHooks";
import { updateForm, fetchOne } from "../../lib/handlers";

export default function EditSalesCategory() {
    const [formData, setFormData] = useState<SalesCategoryPayload>({
		name: "",
  	});
  	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const updateSalesCategory = useEditSalesCategory();
	const getSalesCategories = useGetSalesCategories();
	const info = useAppSelector((state) => state.salesCategories);
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			fetchOne(sanitizeValue(params.id), getSalesCategory, setFormData);
		}
	}, [params]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (validate<SalesCategoryPayload>(formData, "salesCategory")) {
			const updateParams: Params = {
				offset: (info.page - 1) * 10,
				limit: 10,
			};
			updateSalesCategory(sanitizeValue(params.id), formData, () => {
				getSalesCategories(dispatch, updateParams);
				navigate(-1);
			});
		} else {
			showNotification("error", "Missing Required Fields")
		}
	};

  	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className={styles.titleContainer}>Edit Sales Category</span>
			</div>
			<SalesCategoryForm
				submitHandler={handleSubmit}
				onCancel={() => navigate(-1)}
				updateForm={(e: any) => updateForm(e, formData, setFormData)}
				formData={formData}
			/>
    	</main>
  	);
};
