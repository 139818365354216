import { getCompanies, updateCompany, deleteCompany, addCompany } from "../../lib/api";
import { updateCompanies } from "../features/companySlice";
import type { AppDispatch } from "../store";
import { CompanyModel } from "../../types/models";
import { CompanyPayload } from "../../types/payloads";
import { getApi, mutationApi, deleteApi, Params } from "./apiHooks";

export function useGetCompanies() {
	return async function (dispatch: AppDispatch, params: Params) {
		const page: number = (params.offset / params.limit) + 1;
		const result = await getApi<CompanyModel>(params, getCompanies);
		dispatch(updateCompanies({ list: result.list, count: result.count, page }));
	}
}

export function useAddCompany() {
	return async function (body: CompanyPayload, cb: () => void) {
		if (await mutationApi(addCompany, body, "Company Added", "Unable to Add Company")){
			cb();
		}
	}
}

export function useEditCompany() {
	return async function (id: number, body: CompanyPayload, cb: () => void) {
		if (await mutationApi(updateCompany, { id, body }, "Company Updated", "Unable to Update Company")){
			cb();
		}
	}
}

export function useDeleteCompany() {
	return async function (id: number, cb: () => void) {
		if (await deleteApi(id, deleteCompany, "Company")) {
			cb();
		}
	}
}
